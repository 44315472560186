import React from 'react';
import './small-profile-pic.css';


const SmallProfilePic = (props) => {
      const rideTypeComponent = () => {
        if (props.rideType.indexOf("Skier") > -1){
            return (
                <img
                alt=""
                src="/playground_assets/icons8-skiing-50-200h.png"
                className="small-img"
              />
            )
        } else if (props.rideType.indexOf("Snowboarder") > -1) {
            return (
                <img
                alt=""
                src="/playground_assets/icons8-snowboarding-50-200h.png"
                className="small-img"
                />
            )
        } else {
            return (
                <svg viewBox="0 0 1024 1024" className="smaller-size">
                <path d="M598 256l384 512h-940l256-342 192 256 68-50-120-162z"></path>
              </svg>
            )
        }
      }

      const abilityLevelComponent = () => {
        if (props.ability === "New"){
            return (
                <svg
                viewBox="0 0 877.7142857142857 1024"
                className="smaller-size"
                style={{fill: "red"}}
              >
                <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
              </svg>
            )
        } else if (props.ability === "Beginner") {
            return (
                <svg
                viewBox="0 0 877.7142857142857 1024"
                className="smaller-size"
                style={{fill: "green"}}
              >
                <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
              </svg>
            )

        } else if (props.ability === "Intermediate") {
            return (
                <svg
                viewBox="0 0 877.7142857142857 1024"
                className="smaller-size"
                style={{fill: "blue"}}
              >
                <path d="M877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
            )
        } else if (props.ability === "Advanced") {
            return (
                <svg viewBox="0 0 1024 1024" className="smaller-size">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
            )
        } else if (props.ability === "Expert") {
            return (
                <div className="smaller-size">
                <svg viewBox="0 0 850 1024" className="smallest-size">
                  <path d="M512 0l-320 512 320 512 320-512z"></path>
                </svg>
                <svg viewBox="0 0 850 1024" className="smallest-size">
                  <path d="M512 0l-320 512 320 512 320-512z"></path>
                </svg>
              </div>
            )
        } else {
            return (
                <div className="smaller-size">
                <svg viewBox="0 0 2670 1024" className="smaller-size">
                  <path d="M512 0l-320 512 320 512 320-512z"></path>
                  <path d="M512 0l-320 512 320 512 320-512z" transform="translate(890, 0)"></path>
                  <path d="M512 0l-320 512 320 512 320-512z" transform="translate(1780, 0)"></path>
                </svg>
              </div>
            )
        } 
      }


      return (
        <div className='circle-outline' style={{    
            "display": "flex",
            "flexDirection": "column",
            "alignItems": "center",
            }}>
          <div className='smaller-size'>
            {rideTypeComponent()}
          </div>
          <div className='smaller-size'>
            {abilityLevelComponent()}
          </div>
        </div>
      );
    }

export default SmallProfilePic;
