import React, { useEffect, useState } from 'react'

import MountainCard from './mountain-card'
import '../views/trips-page.css'

import { getSpecificMountains } from "../../../utils/apiHelper.js"

import { Link, } from "react-router-dom";

import LoadingIcon from './loading';


const Trip = (props) => {
    const [mountains, setMountains] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchMountains = async (mountains) => {
        try {
            let json = await getSpecificMountains(mountains);
            return { success: true, data: json };
        } catch (error) {
            console.log(error);
            return { success: false };
        }
    }

    useEffect(() => {
        (async () => {
            if (props.trip.mountains && props.trip.mountains.length > 0) {
                setLoading(true);
                let res = await fetchMountains(props.trip.mountains);
                if (res.success) {
                    setMountains(res.data);
                    setLoading(false);
                }
            }
        })();
    }, [props.trip.mountains])

    return (
        <div style={{width: "100%",   maxWidth: "1000px"    }}>
            {loading ? <div className="trips-page-container01"><LoadingIcon /></div> :
                <div className="trips-page-cards-container1">
                    <div className="trips-page-container09">
                        <div className="trips-page-container10">
                            <h1 className="trips-page-text01">
                                <span>{props.trip.name}</span>
                                <br></br>
                            </h1>
                            <svg
                                viewBox="0 0 1024 1024"
                                className="trips-page-icon06"
                            >
                                <path d="M469.333 128h-298.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v597.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-298.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v298.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-597.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h298.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM759.168 76.501l-405.333 405.333c-5.205 5.163-9.259 11.947-11.221 19.84l-42.667 170.667c-1.664 6.4-1.792 13.568 0 20.693 5.717 22.869 28.885 36.779 51.755 31.061l170.667-42.667c7.125-1.749 14.080-5.504 19.84-11.221l405.333-405.333c25.984-25.984 38.997-60.16 38.997-94.165s-13.013-68.181-38.997-94.165-60.203-39.040-94.208-39.040-68.181 13.013-94.165 38.997zM819.499 136.832c9.344-9.344 21.504-13.995 33.835-13.995s24.491 4.651 33.835 13.995 13.995 21.504 13.995 33.835-4.651 24.491-13.995 33.835l-396.971 396.971-90.197 22.571 22.571-90.197z"></path>
                            </svg>
                            <div className="trips-page-container11"></div>
                        </div>
                        <div className="trips-page-container12">
                            <span>{props.trip.dates[0].slice(0, 10).replaceAll("-", "/")} - {props.trip.dates[1].slice(0, 10).replaceAll("-", "/")}</span>
                            <span className="trips-page-text06">
                            </span>
                            <span className="trips-page-text06">
                                {props.trip.notes}
                            </span>
                        </div>
                    </div>
                    {mountains.map((mountain) => (
                        <MountainCard
                            key={mountain.name}
                            mountain={mountain}
                        ></MountainCard>
                    ))}
                    <Link to="/explore" className="trips-page-container13">
                        <svg viewBox="0 0 1024 1024" className="trips-page-icon10">
                            <path d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM554 298v172h172v84h-172v172h-84v-172h-172v-84h172v-172h84z"></path>
                        </svg>
                        <span>{mountains.length > 0 ? "Find More" : "Add Mountains"}</span>
                    </Link>
                </div>
            }
        </div>
    )
}

export default Trip