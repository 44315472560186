import React from 'react'

import PropTypes from 'prop-types'

import MountainCard from './mountain-card'
import './main.css'

const Main = (props) => {
    const popularMountains = [
        {
            "baseElevation": "  9,600 feet (2,900 m)",
            "coordinates": "39°28′48″N 106°04′01″W﻿ / ﻿39.480°N 106.067°W﻿ / 39.480; -106.067Coordinates: 39°28′48″N 106°04′01″W﻿ / ﻿39.480°N 106.067°W﻿ / 39.480; -106.067",
            "imgThumb": "https://skimap.org/data/510/4111/1629214360thumb.jpg",
            "liftCapacity": "",
            "liftSystem": "33 total (1 gondola, 5 high-speed six-packs, 6 high-speed quads, 1 fixed grip quad, 1 triple chairlift, 6 double chairlifts, 13 surface)",
            "location": "White River National ForestSummit County, Colorado",
            "longestRun": "Four O'Clock3.5 miles (5.6 km)",
            "name": "Breckenridge",
            "nearestMajorCity": "Breckenridge",
            "nightSkiing": "none",
            "runs": {
              "0": 0.14,
              "1": 0.31,
              "2": 0.19,
              "3": 0.36,
              "4": -1,
              "total": 155
            },
            "skiableArea": 2908,
            "snowfall": "370 in (940 cm) per year",
            "snowmaking": "600 acres (2.4 km2)",
            "terrainParks": "25 acres (10 ha)",
            "topElevation": "12,998 feet (3,962 m)",
            "vertical": 3398,
            "website": "breckenridge.com"
          },
          {
            "baseElevation": "8,100 feet (2,500 m)",
            "coordinates": "39°37′53″N 106°31′18″W﻿ / ﻿39.63139°N 106.52167°W﻿ / 39.63139; -106.52167Coordinates: 39°37′53″N 106°31′18″W﻿ / ﻿39.63139°N 106.52167°W﻿ / 39.63139; -106.52167",
            "imgThumb": "https://skimap.org/data/497/1615/1644079453thumb.jpg",
            "liftCapacity": "",
            "liftSystem": "25 total (2 gondolas, 1 Chondola, 12 high-speed quad chairs, 1 fixed grip quad, 1 triple chair, 1 double chair, 7 magic carpets, 1 tow lift)",
            "location": "Eagle County, Colorado, U.S.",
            "longestRun": "Centennial - 2.75 miles (4.4 km)",
            "name": "Beaver Creek",
            "nearestMajorCity": "Avon",
            "nightSkiing": "",
            "runs": {
              "0": 0.19,
              "1": 0.43,
              "2": 0.38,
              "3": -1,
              "4": -1,
              "total": 167
            },
            "skiableArea": 2082,
            "snowfall": "310 inches (790 cm) per yr",
            "snowmaking": "",
            "terrainParks": "4",
            "topElevation": "11,440 feet (3,490 m)",
            "vertical": 3340,
            "website": "beavercreek.com"
          },
          {
            "baseElevation": "6,800 ft (2,073 m)Lone Moose7,500 ft (2,286 m)Mountain Village[1]",
            "coordinates": "45°15′0″N 111°25′0″W﻿ / ﻿45.25000°N 111.41667°W﻿ / 45.25000; -111.41667Coordinates: 45°15′0″N 111°25′0″W﻿ / ﻿45.25000°N 111.41667°W﻿ / 45.25000; -111.41667",
            "imgThumb": "https://skimap.org/data/361/3535/1636156149thumb.jpg",
            "liftCapacity": "",
            "liftSystem": "36 lifts",
            "location": "Big Sky,Madison County,Montana United States",
            "longestRun": "6 miles (10 km)",
            "name": "Big Sky",
            "nearestMajorCity": "Bozeman - 50 mi (80 km)",
            "nightSkiing": "",
            "runs": {
              "0": 0.15,
              "1": 0.25,
              "2": 0.6,
              "3": -1,
              "4": -1,
              "total": 250
            },
            "skiableArea": 5800,
            "snowfall": "",
            "snowmaking": "10%",
            "terrainParks": "",
            "topElevation": "11,166 ft (3,403 m)[1]",
            "vertical": 4350,
            "website": "bigskyresort.com"
          }
    ]
  return (
    <div id="main-section" className="main-main">
      <div className="main-cards-container">
        <h1 className="main-text Heading">{props.heading}</h1>
        {popularMountains.map((mount) => (
            <MountainCard
            mountain={mount}
            key={mount.name}
            ></MountainCard>
        ))}       
      </div>
    </div>
  )
}

Main.defaultProps = {
  heading: 'Popular Ski Areas',
}

Main.propTypes = {
  heading: PropTypes.string,
}

export default Main
