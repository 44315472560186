import React from 'react'

import PropTypes from 'prop-types'
import parse from 'html-react-parser';


import './dash-local-mountain-report.css'

// Markdown to HTML parser
function processString(input) {
    return input.split('\\n').map(piece => {
        piece = piece.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
        if (piece === "") {
            return "<div></div>";
        } else if (piece.startsWith("* ")) {
            return `<ul><li>${piece.substring(2)}</li></ul>`;
        } else {
            return `<div>${piece}</div>`;
        }
    }).join("");
}
  
function MarkdownToReact({ markdown }) {
const html = processString(markdown);
return <div>{parse(html)}</div>
}


const DashLocalMountainReport = (props) => {
  return (
    <div className="dash-local-mountain-report-container">
      <span className="dash-local-mountain-report-text">The Local Mountain Report</span>
      {props.overview ? 
      <div className="dash-local-mountain-report-container1">
        <MarkdownToReact markdown={props.overview}/>
      </div>
    : <span className="dash-local-mountain-report-text05">Coming Soon!</span>}
    </div>
  )
}

DashLocalMountainReport.defaultProps = {
  text2:
    "Alta Ski Area is one of several popular ski destinations in the Salt Lake City Area. It is known for being skiers only, having traversal heavy terrain, and catering to advanced and expert skiers. Some of it's most notable runs are off the high traverse. It also is well known for it's hike accessible terrain like Mt. Baldy Chute.",
  text3: 'Best Runs (Advanced)',
  text4: 'Après Ski',
  text1: 'Overview',
}

DashLocalMountainReport.propTypes = {
  text: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  text1: PropTypes.string,
}

export default DashLocalMountainReport
