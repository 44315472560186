import React from 'react'

import { createUseStyles } from 'react-jss'

const LoadingIcon = (props) => {
  const classes = useStyles()
  return (
    <div className={classes['container']}>
      <img
        src={require('../../public/playground_assets/icons8-skiing-50-200h.png')}
        alt="loading skiier"
        className={classes['image']}
      />
      <img
        src={require('../../public/playground_assets/loadingEllipsis.gif')}
        alt="loadingellipsis"
        className={classes['image1']}
      />
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    width: '50px',
    objectFit: 'cover',
    height: '50px',
  },
  image1: {
    width: '100px',
    objectFit: 'cover',
    height: '50px',
  },
})

export default LoadingIcon
