import React, { useEffect } from "react";
import {
    Link,
    Routes,
    Route,
    useNavigate,
    Navigate,
    useLocation
} from "react-router-dom";
import useAuth, { AuthProvider } from "./utils/useAuth";
import NavBar from "./components/NavBar";

import './teleportv1/src/style.css'
import LandingPage from './teleportv1/src/views/landing-page'
import MountainDashboard from './teleportv1/src/views/mountain-dashboard'
import ExplorePage from './teleportv1/src/views/explore-page'
import LoginPage from './teleportv1/src/views/login-page'
import ProfileSetup from './teleportv1/src/views/profile-setup'
import SavedMountainPage from './teleportv1/src/views/saved-mountain-page'
import SignUpPage from './teleportv1/src/views/sign-up-page'
import TripsPage from './teleportv1/src/views/trips-page'
import TermsAndConditions from './teleportv1/src/views/terms-and-conditions'
import NavBarPrivate from "./teleportv1/src/components/nav-bar-private";
import NavBarPublic from "./teleportv1/src/components/nav-bar-public";
import Footer from "./teleportv1/src/components/footer";
import ProfilePage from "./teleportv1/src/views/profile-page";
import ProfileEdit from "./teleportv1/src/views/profile-edit";
import AboutPage from "./teleportv1/src/views/about-page";


const Settings = () => <h1>Settings (Private)</h1>;

// const Login = () => {
//   const navigate = useNavigate();
//   const { login } = useAuth();
//   const { state } = useLocation();

//   const handleLogin = () => {
//     login().then(() => {
//       //navigate(state?.path || "/");
//       navigate('/profile-setup')
//     });
//   };

//   return (
//     <div>
//       <h1>Login</h1>
//       <button onClick={handleLogin}>Log in</button>
//     </div>
//   );
// };

function RequireAuth({ children }) {
    const { authed } = useAuth();
    const location = useLocation();

    return authed === true ? (
        children
    ) : (
        <Navigate to="/signup" replace state={{ path: location.pathname }} />
    );
}

function RequireProfile({ children }) {
    const { user } = useAuth();
    const location = useLocation();

    return user && 'abilityLevel' in user ? (
        children
    ) : (
        <Navigate to="/profile-setup" replace state={{ path: location.pathname }} />
    );
}

function RequireProfileOrNew({ children }) {
    const { user, authed } = useAuth();
    const location = useLocation();

    return !authed || (user && 'abilityLevel' in user) ? (
        children
    ) : (
        <Navigate to="/profile-setup" replace state={{ path: location.pathname }} />
    );
}

export default function App() {
    const { authed, user } = useAuth();
    console.log(user)

    return (
        <div>
            <AuthProvider>
            <Routes>
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/about" element={<AboutPage/>}/>
                <Route
                    path="/"
                    element={
                        <RequireProfileOrNew>
                            <LandingPage authed={authed} />
                        </RequireProfileOrNew>
                    }
                />
                <Route
                    path="/explore/:query"
                    element={
                        <RequireProfileOrNew>
                                <ExplorePage />
                        </RequireProfileOrNew>
                    }
                />
                <Route
                    path="/explore/"
                    element={
                        <RequireProfileOrNew>
                                <ExplorePage />
                        </RequireProfileOrNew>
                    }
                />
                <Route
                    path="/dashboard/:id"
                    element={
                        <RequireProfileOrNew>
                            <MountainDashboard />
                        </RequireProfileOrNew>
                    }
                />
                <Route
                    path="/saved"
                    element={
                        <RequireAuth>
                            <RequireProfile>
                                <SavedMountainPage />
                            </RequireProfile>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/trips"
                    element={
                        <RequireAuth>
                            <RequireProfile>
                                <TripsPage />
                            </RequireProfile>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <RequireAuth>
                            <RequireProfile>
                                <Settings />
                            </RequireProfile>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/profile-setup"
                    element={
                        <RequireAuth>
                            {user && "abilityLevel" in user ? <ProfileEdit/> : <ProfileSetup user={user} />}
                        </RequireAuth>
                    }
                />
                <Route
                path="/termsandconditions"
                element={
                    <div>
                        {authed ? <NavBarPrivate/> : <NavBarPublic/>}
                        <TermsAndConditions />
                        <Footer/>
                    </div>
                }
                />
                <Route
                path="/profile-page"
                element={
                    <RequireAuth>
                        <RequireProfile>
                            <ProfilePage/>
                        </RequireProfile>
                    </RequireAuth>
                }
                />
                <Route
                path="/profile-edit"
                element={
                    <RequireAuth>
                        <RequireProfile>
                            <ProfileEdit/>
                        </RequireProfile>
                    </RequireAuth>
                }
                />
            </Routes>
            </AuthProvider>
        </div>
    );
}
