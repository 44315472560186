import React, {useContext, useState} from 'react'

import { useNavigate, useLocation } from "react-router-dom";

import './profile-setup.css'

import { authContext } from '../../../utils/useAuth';
import { addUserProfile } from '../../../utils/apiHelper';

const ProfileSetup = (props) => {
    const [rideType, setRideType] = useState(["Skier"])
    const [abilityLevel, setAbilityLevel] = useState('Beginner')

    const [family, setFamily] = useState(0.5)
    const [park, setPark] = useState(0.5)
    const [groomer, setGroomer] = useState(0.5)
    const [powder, setPowder] = useState(0.5)
    const [moguls, setMoguls] = useState(0.5)
    const [trees, setTrees] = useState(0.5)
    const [extreme, setExtreme] = useState(0.5)

    const [location, setLocation] = useState("")
    const [country, setCountry] = useState('USA');

    const handleLocationChange = event => {
        setLocation(event.target.value)
    }

    const navigate = useNavigate();
    const { state } = useLocation();
    const {user, login} = useContext(authContext)

    const handleSubmit = async () => {
        const newUser = {
            'abilityLevel' : abilityLevel,
            'email' : user.email,
            'rideType': rideType,
            'preferences': {
                'family': family,
                'park': park,
                'groomer': groomer,
                'powder': powder,
                'moguls': moguls,
                'trees': trees,
                'extreme': extreme
            },
            'location': location,
            'country': country,
            'name': user.displayName,
            'visited': [],
            'wantToVisit': [],
            'trips': [],
            'ratings': {}
        }

        const statusUserProfile = await addUserProfile(newUser, user.uid)

        if (statusUserProfile === 200){
            newUser['uid'] = user.uid
            login(newUser)
            navigate(state?.path || '/explore', {replace: true})
        } else {
            console.error("error saving profile: " + statusUserProfile[1])
        }
    }

  return (
    <div className="profile-setup-container">
      <div className="profile-setup-container01">
        <h1 className="profile-setup-text">
          <span className="profile-setup-text01">Welcome to Alpinac</span>
          <br></br>
        </h1>
        <h1 className="profile-setup-text03">
          Set up profile for the best experience
        </h1>
        <div className="profile-setup-container02">
          <svg viewBox="0 0 1024 1024" className="profile-setup-icon">
            <path d="M598 256l384 512h-940l256-342 192 256 68-50-120-162z"></path>
          </svg>
          <h1 className="profile-setup-text04">Ride Type</h1>
        </div>
        <div className="profile-setup-ride-type">
          <div onClick={() => {setRideType(rideType.indexOf("Skier") > -1 ? rideType.filter(r => r !== "Skier") : [...rideType, "Skier"])}} className={!rideType.includes("Skier") ? "profile-setup-ride" : "profile-setup-ride-select"}>
            <img
              alt=""
              src="/playground_assets/icons8-skiing-50-200h.png"
              className="profile-setup-image"
            />
            <span className="profile-setup-text05">Skier</span>
          </div>
          <div onClick={() => {setRideType(rideType.indexOf("Snowboarder") > -1 ? rideType.filter(r => r !== "Snowboarder") : [...rideType, "Snowboarder"])}} className={!rideType.includes("Snowboarder") ? "profile-setup-ride" : "profile-setup-ride-select"}>
            <img
              alt=""
              src="/playground_assets/icons8-snowboarding-50-200h.png"
              className="profile-setup-image1"
            />
            <span className="profile-setup-text06">Snowboarder</span>
          </div>
          <div onClick={() => {setRideType(rideType.indexOf("Other") > -1 ? rideType.filter(r => r !== "Other") : [...rideType, "Other"])}} className={!rideType.includes("Other") ? "profile-setup-ride" : "profile-setup-ride-select"}>
            <svg viewBox="0 0 1024 1024" className="profile-setup-icon02">
              <path d="M598 256l384 512h-940l256-342 192 256 68-50-120-162z"></path>
            </svg>
            <span className="profile-setup-text07">Other</span>
          </div>
        </div>
        <div className="profile-setup-container06">
          <svg viewBox="0 0 1024 1024" className="profile-setup-icon04">
            <path d="M598 256l384 512h-940l256-342 192 256 68-50-120-162z"></path>
          </svg>
          <h1 className="profile-setup-text08">Ability Level</h1>
        </div>
        <div className="profile-setup-ability-levels">
          <div onClick={() => setAbilityLevel("New")} className={abilityLevel === "New" ? "profile-setup-ability-select" : "profile-setup-ability"}>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="profile-setup-icon06"
            >
              <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
            </svg>
            <span className="profile-setup-text09">New</span>
          </div>
          <div onClick={() => setAbilityLevel("Beginner")} className={abilityLevel === "Beginner" ? "profile-setup-ability-select" : "profile-setup-ability"}>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="profile-setup-icon08"
            >
              <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
            </svg>
            <span className="profile-setup-text10">Beginner</span>
          </div>
          <div onClick={() => setAbilityLevel("Intermediate")} className={abilityLevel === "Intermediate" ? "profile-setup-ability-select" : "profile-setup-ability"}>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="profile-setup-icon10"
            >
              <path d="M877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
            </svg>
            <span className="profile-setup-text11">Intermediate</span>
          </div>
          <div onClick={() => setAbilityLevel("Advanced")} className={abilityLevel === "Advanced" ? "profile-setup-ability-select" : "profile-setup-ability"}>
            <svg viewBox="0 0 1024 1024" className="profile-setup-icon12">
              <path d="M512 0l-320 512 320 512 320-512z"></path>
            </svg>
            <span className="profile-setup-text12">
              <span>Advanced</span>
              <br></br>
            </span>
          </div>
          <div onClick={() => setAbilityLevel("Expert")} className={abilityLevel === "Expert" ? "profile-setup-ability-select" : "profile-setup-ability"}>
            <div className="profile-setup-container12">
              <svg viewBox="0 0 700 1024" className="profile-setup-icon14">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
              <svg viewBox="150 0 1024 1024" className="profile-setup-icon16">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
            </div>
            <span className="profile-setup-text15">
              <span>Expert</span>
              <br></br>
            </span>
          </div>
          <div onClick={() => setAbilityLevel("Extreme")} className={abilityLevel === "Extreme" ? "profile-setup-ability-select" : "profile-setup-ability"}>
            <div className="profile-setup-container14">
              <svg viewBox="0 0 650 1024" className="profile-setup-icon18">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="profile-setup-icon20">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
              <svg viewBox="180 0 1024 1024" className="profile-setup-icon22">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
            </div>
            <span className="profile-setup-text18">Extreme</span>
          </div>
        </div>
        <div className="profile-setup-container15">
          <svg viewBox="0 0 1024 1024" className="profile-setup-icon24">
            <path d="M598 256l384 512h-940l256-342 192 256 68-50-120-162z"></path>
          </svg>
          <h1 className="profile-setup-text19">
            <span>Home Base</span>
            <br></br>
          </h1>
        </div>
        <div className="profile-setup-container16">
          <input
            type="text"
            placeholder="Chicago, IL"
            className="profile-setup-textinput input"
            value={location}
            onChange={handleLocationChange}
          />
          <select value={country} onChange={(event) => setCountry(event.target.value)} name="Country" className="profile-setup-select">
            <option value="USA">
              USA
            </option>
            <option value="CAN">CAN</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="profile-setup-container17">
          <svg viewBox="0 0 1024 1024" className="profile-setup-icon26">
            <path d="M598 256l384 512h-940l256-342 192 256 68-50-120-162z"></path>
          </svg>
          <h1 className="profile-setup-text22">Trail Preferences</h1>
        </div>
        <div className="profile-setup-sliders">
          <span className="profile-setup-text23">
            <span>Family</span>
            <br></br>
          </span>
          <div className="profile-setup-slider-container">
            <span style={{opacity: (1.0-family), fontWeight: '600'}} className="profile-setup-small-text">Don't Enjoy </span>
            <input className="profile-setup-slider" type="range" defaultValue={0.5} max={1} min={0} step={0.05} value={family} onChange={(event) => setFamily(event.target.value)}/>
            <span style={{opacity: family, fontWeight: '600'}} className="profile-setup-small-text"> Really Enjoy</span>
          </div>
          <span className="profile-setup-text23">
            <span>Park</span>
            <br></br>
          </span>
          <div className="profile-setup-slider-container">
            <span style={{opacity: (1.0-park), fontWeight: '600'}} className="profile-setup-small-text">Don't Enjoy </span>
            <input className="profile-setup-slider" type="range" defaultValue={0.5} max={1} min={0} step={0.05} value={park} onChange={(event) => setPark(event.target.value)}/>
            <span style={{opacity: park, fontWeight: '600'}} className="profile-setup-small-text"> Really Enjoy</span>
          </div>
          <span className="profile-setup-text23">
            <span>Groomed</span>
            <br></br>
          </span>
          <div className="profile-setup-slider-container">
            <span style={{opacity: (1.0-groomer), fontWeight: '600'}} className="profile-setup-small-text">Don't Enjoy </span>
            <input className="profile-setup-slider" type="range" defaultValue={0.5} max={1} min={0} step={0.05} value={groomer} onChange={(event) => setGroomer(event.target.value)}/>
            <span style={{opacity: groomer, fontWeight: '600'}} className="profile-setup-small-text"> Really Enjoy</span>
          </div>
          <span className="profile-setup-text23">
            <span>Powder</span>
            <br></br>
          </span>
          <div className="profile-setup-slider-container">
            <span style={{opacity: (1.0-powder), fontWeight: '600'}} className="profile-setup-small-text">Don't Enjoy </span>
            <input className="profile-setup-slider" type="range" defaultValue={0.5} max={1} min={0} step={0.05} value={powder} onChange={(event) => setPowder(event.target.value)}/>
            <span style={{opacity: powder, fontWeight: '600'}} className="profile-setup-small-text"> Really Enjoy</span>
          </div>
          <span className="profile-setup-text23">
            <span>Moguls</span>
            <br></br>
          </span>
          <div className="profile-setup-slider-container">
            <span style={{opacity: (1.0-moguls), fontWeight: '600'}} className="profile-setup-small-text">Don't Enjoy </span>
            <input className="profile-setup-slider" type="range" defaultValue={0.5} max={1} min={0} step={0.05} value={moguls} onChange={(event) => setMoguls(event.target.value)}/>
            <span style={{opacity: moguls, fontWeight: '600'}} className="profile-setup-small-text"> Really Enjoy</span>
          </div>
          <span className="profile-setup-text23">
            <span>Trees</span>
            <br></br>
          </span>
          <div className="profile-setup-slider-container">
            <span style={{opacity: (1.0-trees), fontWeight: '600'}} className="profile-setup-small-text">Don't Enjoy </span>
            <input className="profile-setup-slider" type="range" defaultValue={0.5} max={1} min={0} step={0.05} value={trees} onChange={(event) => setTrees(event.target.value)}/>
            <span style={{opacity: trees, fontWeight: '600'}} className="profile-setup-small-text"> Really Enjoy</span>
          </div>
          <span className="profile-setup-text23">
            <span>Extreme</span>
            <br></br>
          </span>
          <div className="profile-setup-slider-container">
            <span style={{opacity: (1.0-extreme), fontWeight: '600'}} className="profile-setup-small-text">Don't Enjoy </span>
            <input className="profile-setup-slider" type="range" defaultValue={0.5} max={1} min={0} step={0.05} value={extreme} onChange={(event) => setExtreme(event.target.value)}/>
            <span style={{opacity: extreme, fontWeight: '600'}} className="profile-setup-small-text"> Really Enjoy</span>
          </div>
        </div>
        <div className="profile-setup-container20">
          <button onClick={() => handleSubmit()} className="profile-setup-button button">Continue</button>
        </div>
      </div>
    </div>
  )
}

export default ProfileSetup
