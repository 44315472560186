import React from 'react'

import PropTypes from 'prop-types'

import './navigation-links.css'
import { Link } from "react-router-dom";

const NavigationLinks = (props) => {
  return (
    <nav className={`navigation-links-nav ${props.rootClassName} `}>
      <span className="navigation-links-text"><Link to="/explore">Explore</Link></span>
      <span className="navigation-links-text"><Link to="/trips">Trips</Link></span>
      <span className="navigation-links-text"><Link to="/saved">Saved</Link></span>
    </nav>
  )
}

NavigationLinks.defaultProps = {
  rootClassName: '',
}

NavigationLinks.propTypes = {
  rootClassName: PropTypes.string,
}

export default NavigationLinks
