import { async } from "@firebase/util";

const testApiUrl = 'http://127.0.0.1:8080'
const apiUrlProd = 'https://alpinacapi-v52hnfkibq-uc.a.run.app/'
const apiUrl = apiUrlProd

export async function getUserData(uid) {
    const resp = await fetch(apiUrl + "/users?user=" + uid)
    let data = await resp.json();
    return data
}

export async function addUserProfile(userProfile, uid) {
    const resp = await fetch(apiUrl+"/addUser", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'prof': userProfile, 'uid': uid })
    })
    const statusUserprofile = await resp.status;

    return statusUserprofile
}

export async function getAllMountains() {
    const resp = await fetch(apiUrl + "/mountain")
    const data = await resp.json();
    return data.allMountains
}

export async function getSpecificMountains(mountains) {
    const resp = await fetch(apiUrl + "/mountain?mountain=" + mountains.join(';'))
    const data = await resp.json();
    return data.mountains
}

export async function updateProfile(uid, property, value) {
    const resp = await fetch(apiUrl+"/updateProfile", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'path': property, 'value': value, 'uid': uid })
    })
    const statusUserprofile = await resp.status;
    const prof = await resp.json();

    return {status: statusUserprofile, updatedUser: prof.user}
}

export async function updateMountainInfoAsync(rating, review, mountainName) {
    const resp = await fetch(apiUrl+"/mountain/rate?mountain=" + mountainName, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'rating': rating, 'review': review })
    })
    const status = await resp.status;

    return {status: status}
}

export async function getMountainInfo(mtnName) {
    const resp = await fetch(apiUrl+"/getMountainInfo?mtnName=" + mtnName)
    //const status = await resp.status;
    const mtn = await resp.json();

    return mtn
}

// export async function getUserData(uid) {
//     const resp = await fetch("https://api-sf7jwpk43a-uc.a.run.app" + "/users?user=" + uid)
//     const data = await resp.json();
//     return data
// }

// export async function addUserProfile(userProfile, uid) {
//     const resp = await fetch("https://api-sf7jwpk43a-uc.a.run.app/addUser", {
//         method: 'POST',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ 'prof': userProfile, 'uid': uid })
//     })
//     const statusUserprofile = await resp.status;

//     return statusUserprofile
// }

// export async function getAllMountains() {
//     const resp = await fetch("https://api-sf7jwpk43a-uc.a.run.app" + "/getAllMountains")
//     const data = await resp.json();
//     console.log(data)
//     return data.allMountains
// }