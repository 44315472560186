import React from 'react';

const Triangle = ({ percentages }) => {

  // Define the colors for each rectangle
  const colors = ['#8BC34A', '#4A90E2', 'black'];

  function calculateVars(BASE, HEIGHT, I) {
    //const tan60 = Math.tan(Math.PI / 3); // tangent of 60 degrees
    const h0 = HEIGHT * I[0];
    const s0 = h0 / 2;
    const b0 = BASE;
    const h1 = HEIGHT * I[1];
    const s1 = h1 / 2;
    const b1 = BASE - 2 * h0 / 2;
    const h2 = HEIGHT * I[2];
    const s2 = h2 / 2;
    const b2 = b1 - 2 * h1 / 2;
    return { b0, h0, s0, b1, h1, s1, b2, h2, s2 };
  }

    const BASE = 180;
    const HEIGHT = 180;
    const I = percentages;

    const result = calculateVars(BASE, HEIGHT, I);
  

  return (
    <div style={{width: 200, height: 200, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "16px"}}>
      <div style={{ width: result.b2, height: 0, borderLeft: `${result.s2}px solid transparent`, borderRight: `${result.s2}px solid transparent`, borderBottom: `${result.h2}px solid ${colors[2]}`, position: 'relative', overflow: 'hidden' }}/>
      <div style={{ width: result.b1, height: 0, borderLeft: `${result.s1}px solid transparent`, borderRight: `${result.s1}px solid transparent`, borderBottom: `${result.h1}px solid ${colors[1]}`, position: 'relative', overflow: 'hidden' }}/>
      <div style={{ width: result.b0, height: 0, borderLeft: `${result.s0}px solid transparent`, borderRight: `${result.s0}px solid transparent`, borderBottom: `${result.h0}px solid ${colors[0]}`, position: 'relative', overflow: 'hidden' }}/>
      <div style={{width:200, height: 20}} />
    </div>
  );
}

export default Triangle;
