import React, { useState, useEffect } from 'react'

import './modal-add-to-trip.css'

import Modal from 'react-modal';

import { authContext } from "../../../utils/useAuth";

import {updateProfile} from "../../../utils/apiHelper.js"
import LoadingIcon from './loading';

const ModalAddToTrip = (props) => {

    const authCont = React.useContext(authContext)
    const user = authCont.user;
    const login = authCont.login;

    const [trips, setTrips] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setTrips(user.trips)
    }, [user.trips, props.isModalOpen])

    const updateProfileProperty = async (property, value) => {
        setLoading(true)
        const {status, updatedUser} = await updateProfile(user.uid, property, value);
        if (status !== 200) {
          console.error("Something went wrong updating data :(")
        } else {
            login(updatedUser)
            props.setIsModalOpen(false)
            console.log(updatedUser)
        }
        setLoading(false)
      }

    function updateMountains(data, mountain, name) {
        return data.map(item => {
          if (item.name === name) {
            if (!item.mountains){
                return {...item, mountains: [mountain]}
            } else if (item.mountains.includes(mountain)) {
              return {...item, mountains: item.mountains.filter(m => m !== mountain)};
            } else {
              return {...item, mountains: [...item.mountains, mountain]};
            }
          }
          return item;
        });
      }

    const handleCheckChange = (trip) => {
        setTrips(updateMountains(trips, props.mountainName, trip.name))
    }

    const saveTripUpdates = () => {
        updateProfileProperty('trips', trips)
    }

  return (
    <Modal
    isOpen={props.isModalOpen}
    onRequestClose={() => props.setIsModalOpen(false)}
    shouldCloseOnOverlayClick={true}
    ariaHideApp={false}
    className="modal-add-to-trip-container"
    >
    {loading ? <div className="modal-add-to-trip-container1"><LoadingIcon/></div> :
      <div className="modal-add-to-trip-container1">
        <button className="modal-add-to-trip-button button" onClick={() => props.setIsModalOpen(false)}>
          <svg viewBox="0 0 1024 1024" className="modal-add-to-trip-icon">
            <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
          </svg>
        </button>
        <div className="modal-add-to-trip-container2">
          <h1 className="modal-add-to-trip-text">Add to a trip </h1>
          <svg viewBox="0 0 822.272 1024" className="modal-add-to-trip-icon02">
            <path d="M786.286 91.429c36.571 36.571 0 128-54.857 182.857l-92 92 91.429 397.714c1.714 6.857-1.143 14.286-6.857 18.857l-73.143 54.857c-2.857 2.286-6.857 3.429-10.857 3.429-1.143 0-2.286 0-4-0.571-5.143-1.143-9.714-4-12-9.143l-159.429-290.286-148 148 30.286 110.857c1.714 6.286 0 12.571-4.571 17.714l-54.857 54.857c-3.429 3.429-8.571 5.143-13.143 5.143h-1.143c-5.714-0.571-10.286-2.857-13.714-7.429l-108-144-144-108c-4.571-2.857-6.857-8-7.429-13.143s1.714-10.286 5.143-14.286l54.857-55.429c3.429-3.429 8.571-5.143 13.143-5.143 1.714 0 3.429 0 4.571 0.571l110.857 30.286 148-148-290.286-159.429c-5.143-2.857-8.571-8-9.714-13.714-0.571-5.143 1.143-11.429 5.143-15.429l73.143-73.143c4.571-4 11.429-6.286 17.143-4.571l380 90.857 91.429-91.429c54.857-54.857 146.286-91.429 182.857-54.857z"></path>
          </svg>
        </div>
        <div className="modal-add-to-trip-container3">
          <div className="modal-add-to-trip-container4">
            <button onClick={() => props.setIsCreateTripModalOpen(true)} className="modal-add-to-trip-button1 button">
              <svg
                viewBox="0 0 804.5714285714286 1024"
                className="modal-add-to-trip-icon04"
              >
                <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
              </svg>
            </button>
            <span className="modal-add-to-trip-text1">Create new trip</span>
          </div>
        </div>
        <div className='modal-add-to-trip-trip-containers'>
        {trips && trips.map((trip) => (
                <div key={trip.name} className="modal-add-to-trip-container5">
                    <div className="modal-add-to-trip-container6">
                      <svg viewBox="0 0 1024 1024" className="modal-add-to-trip-icon06">
                        <path d="M598 256l384 512h-940l256-342 192 256 68-50-120-162z"></path>
                      </svg>
                      <span className="modal-add-to-trip-text2">
                        {trip.name}
                      </span>
                    </div>
                    <input
                      type="checkbox"
                      checked={trip.mountains && trip.mountains.includes(props.mountainName)}
                      onChange={() => handleCheckChange(trip)}
                      className="modal-add-to-trip-checkbox"
                    />
                 </div>
        ))}
        </div>
        <button onClick={() => saveTripUpdates()} className="modal-add-to-trip-button2 button">Save</button>
      </div>
    }
    </Modal>
  )
}

export default ModalAddToTrip
