import React, {useEffect, useState} from 'react'

import { Helmet } from 'react-helmet'

import MountainCardVisited from '../components/mountain-card-visited'
import MountainCard from '../components/mountain-card'
import Footer from '../components/footer'
import './saved-mountain-page.css'
import NavBarPrivate from '../components/nav-bar-private'

import {getSpecificMountains} from "../../../utils/apiHelper.js"

import { authContext } from "../../../utils/useAuth";

const SavedMountainPage = (props) => {

  const { user } = React.useContext(authContext)

  const [visitedMountains, setVisitedMountains] = useState([]);
  const [displayVisitedMountains, setDisplayVisitedMountains] = useState([]);
  const [wantVisitedMountains, setWantVisitMountains] = useState([]);
  const [displayWantVisitedMountains, setDisplayWantVisitMountains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");

  const fetchMountains = async (mountains) => {
    try {
      let json = await getSpecificMountains(mountains);
      return { success: true, data: json };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }

  const handleFilter = (queryBy) => {
    let q = queryBy.toLowerCase();
    setDisplayVisitedMountains(visitedMountains.filter(m => m.name.toLowerCase().includes(q) || m.location.toLowerCase().includes(q)|| m.nearestMajorCity.toLowerCase().includes(q)))
    setDisplayWantVisitMountains(wantVisitedMountains.filter(m => m.name.toLowerCase().includes(q) || m.location.toLowerCase().includes(q)|| m.nearestMajorCity.toLowerCase().includes(q)))
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (user.visited?.length > 0){
        let res = await fetchMountains(user.visited);
        if (res.success) {
          setVisitedMountains(res.data);
          setDisplayVisitedMountains(res.data);
          console.log(res.data)
        } else {
          console.error("error retrieving mountains")
        }
      }
      if (user.wantToVisit?.length > 0) {
        let res = await fetchMountains(user.wantToVisit);
        if (res.success) {
          setWantVisitMountains(res.data);
          setDisplayWantVisitMountains(res.data);
        } else {
          console.error("error retrieving mountains")
        }
      }
      setLoading(false)
    })();
  }, [user]);

  return (
    <div className="saved-mountain-page-container">
      <Helmet>
        <title>SavedMountainPage - Alpinac</title>
        <meta property="og:title" content="SavedMountainPage - Alpinac" />
      </Helmet>
      <div className="saved-mountain-page-top-container">
        <div className="saved-mountain-page-container1" style={{zIndex: 999}}>
          <NavBarPrivate rootClassName="nav-bar-private-root-class-name"></NavBarPrivate>
        </div>
        <div className="saved-mountain-page-container2"></div>
        <div className="saved-mountain-page-main">
          <div className="saved-mountain-page-container3">
            <div className="saved-mountain-page-container4">
              <div className="saved-mountain-page-container5">
                <div className="saved-mountain-page-container6">
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="saved-mountain-page-icon"
                  >
                    <path d="M658.286 475.429c0-141.143-114.857-256-256-256s-256 114.857-256 256 114.857 256 256 256 256-114.857 256-256zM950.857 950.857c0 40-33.143 73.143-73.143 73.143-19.429 0-38.286-8-51.429-21.714l-196-195.429c-66.857 46.286-146.857 70.857-228 70.857-222.286 0-402.286-180-402.286-402.286s180-402.286 402.286-402.286 402.286 180 402.286 402.286c0 81.143-24.571 161.143-70.857 228l196 196c13.143 13.143 21.143 32 21.143 51.429z"></path>
                  </svg>
                  <input
                    type="text"
                    placeholder="Search by name or location"
                    className="saved-mountain-page-textinput input"
                  />
                  <a
                    href="https://www.google.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="saved-mountain-page-link"
                  >
                    <svg
                      viewBox="0 0 1024 1024"
                      className="saved-mountain-page-icon2"
                    >
                      <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="saved-mountain-page-container8">
                <div className="saved-mountain-page-cards-container">
                  <h1 className="saved-mountain-page-text2 Heading">
                      Visited
                  </h1>
                  {displayVisitedMountains.length === 0  && query.length !== 0 && <h1 style={{color: 'gray'}}>No Results :/</h1>}
                  {visitedMountains.length === 0  && query.length === 0 && <h2 style={{color: 'gray'}}>Mountains you've visited will be here!</h2>}
                  {displayVisitedMountains.map((mountain) => (
                  <MountainCardVisited
                  key={mountain.name}
                  mountain={mountain}
                  user={user}
                  ></MountainCardVisited>
                  ))}
                </div>
                <div className="saved-mountain-page-cards-container">
                  <h1 className="saved-mountain-page-text2 Heading">
                    Want to visit
                </h1>
                  {console.log(displayWantVisitedMountains)}
                  {displayWantVisitedMountains.length === 0  && query.length !== 0 && <h1 style={{color: 'gray'}}>No Results :/</h1>}
                  {wantVisitedMountains.length === 0  && query.length === 0 && <h2 style={{color: 'gray'}}>Mountains you want to visit will be here!</h2>}
                  {displayWantVisitedMountains.map((mountain) => (
                    <MountainCard
                    key={mountain.name}
                    mountain={mountain}
                  ></MountainCard>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default SavedMountainPage
