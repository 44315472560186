import React, { useState, useEffect} from 'react'

import { Helmet } from 'react-helmet'

import NavBarPrivate from '../components/nav-bar-private'
import Footer from '../components/footer'
import './trips-page.css'
import Trip from '../components/trip'

import {getSpecificMountains} from "../../../utils/apiHelper.js"

import { authContext } from "../../../utils/useAuth";

import ModalCreateTrip from '../components/modal-create-trip';

const TripsPage = (props) => {

  const { user, authed } = React.useContext(authContext)

  const [displayTrips, setDisplayTrips] = useState([]);
  const [trips, setTrips] = useState([]);
  const [query, setQuery] = useState("");


  const handleFilter = (queryBy) => {
    let q = queryBy.toLowerCase();
    setDisplayTrips(trips.filter(m => m.name.toLowerCase().includes(q)))
  }

  useEffect(() => {
    if (user.trips){
        setTrips(user.trips)
        setDisplayTrips(user.trips)
    }
  }, [user]);

  const [isCreateTripModalOpen, setIsCreateTripModalOpen] = useState(false);


  return (
    <div className="trips-page-container">
      <Helmet>
        <title>TripsPage - Alpinac</title>
        <meta property="og:title" content="TripsPage - Alpinac" />
      </Helmet>
      <div className="trips-page-top-container">
        <div className="trips-page-container01">
        <NavBarPrivate rootClassName="nav-bar-private-root-class-name"></NavBarPrivate>
        </div>
        <div className="trips-page-container02"></div>
        {authed && <ModalCreateTrip isModalOpen={isCreateTripModalOpen} setIsModalOpen={setIsCreateTripModalOpen}/>}
        <div className="trips-page-main">
          <div className="trips-page-container03">
            <div className="trips-page-container04">
              <div className="trips-page-container05">
                <div className="trips-page-container06">
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="trips-page-icon"
                  >
                    <path d="M658.286 475.429c0-141.143-114.857-256-256-256s-256 114.857-256 256 114.857 256 256 256 256-114.857 256-256zM950.857 950.857c0 40-33.143 73.143-73.143 73.143-19.429 0-38.286-8-51.429-21.714l-196-195.429c-66.857 46.286-146.857 70.857-228 70.857-222.286 0-402.286-180-402.286-402.286s180-402.286 402.286-402.286 402.286 180 402.286 402.286c0 81.143-24.571 161.143-70.857 228l196 196c13.143 13.143 21.143 32 21.143 51.429z"></path>
                  </svg>
                  <input
                    type="text"
                    placeholder="Search by trip name"
                    className="trips-page-textinput input"
                  />
                  <a
                    href="https://www.google.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="trips-page-link"
                  >
                    <svg viewBox="0 0 1024 1024" className="trips-page-icon02">
                      <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                  </a>
                </div>
                <div className="trips-page-cards-container">
                  <div onClick={() => setIsCreateTripModalOpen(true)} className="trips-page-container07">
                    <svg viewBox="0 0 1024 1024" className="trips-page-icon04">
                      <path d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM554 298v172h172v84h-172v172h-84v-172h-172v-84h172v-172h84z"></path>
                    </svg>
                    <h2 className="trips-page-text">Create New Trip</h2>
                  </div>
                </div>
              </div>
              <div className="trips-page-container08">
              {!displayTrips.length && <span className='trips-page-placeholder-text'>Keep track of your upcoming ski trips here!</span>}
                {displayTrips.map((t) => (
                    <Trip trip={t} key={t.name}/>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default TripsPage
