import React, { useState, useEffect, useRef } from 'react'

import './mountain-card-visited.css'

import { imageExists } from '../../../utils/imgChecker';

import { useNavigate } from "react-router-dom";

import { authContext } from "../../../utils/useAuth";

import ModalSaveMountain from './modal-save-mountain';
import ModalAddToTrip from './modal-add-to-trip'
import ModalCreateTrip from './modal-create-trip';
import ModalRateMountain from './modal-rate-mountain';
import { getInfoDisplayReady } from '../../../utils/dataProcessors';

import { CSSTransition } from 'react-transition-group';

import {IMAGE_THUMB_BASE_URL} from '../Constants.js';



const MountainCardVisited = (props) => {
  const [above, setAbove] = useState(false)
  const [wantToVisit, setWantToVisit] = useState(false)
  const [visited, setVisited] = useState(false)
  const [trip, setTrip] = useState(false)
  const navigate = useNavigate();

    // Mountain Properties
    const [mtnImage, setImage] = useState('https://images.unsplash.com/photo-1600477114512-77ddbbe49e9e?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDYyfHx2YWlsJTIwbW91bnRhaW58ZW58MHx8fHwxNjY4Mjc1NTQ3&ixlib=rb-4.0.3&w=400')
    const [mtnName, setName] = useState("")
    const [ratings, setRatings] = useState([])

    // const [rating, setRating] = useState("0 (0)")

    const displayMtnFacts = getInfoDisplayReady(props.mountain)

    const authCont = React.useContext(authContext)
    const user = authCont.user;
  
    useEffect(() => {
      let mountain = props.mountain
      setName(mountain.name)
      setRatings((user.ratings && user.ratings[mountain.name]) ? user.ratings[mountain.name] : [])
    //   setRating()
  
      const validUrl = (bool) => {
          if (bool){
            setImage(IMAGE_THUMB_BASE_URL + props.mountain.DisplayName.replaceAll(" ","_") + ".jpg")
          }
        }
  
      (async () => {
        props.mountain.DisplayName && imageExists(IMAGE_THUMB_BASE_URL + props.mountain.DisplayName.replaceAll(" ","_") + ".jpg", validUrl)     
      })();

      setVisited(user?.visited && user.visited.includes(mountain.name))
      setWantToVisit(user?.wantToVisit && user.wantToVisit.includes(mountain.name))
      setTrip(user?.trips && Object.values(user.trips).some(trip => trip.mountains && trip.mountains.includes(mountain.name)))
  
    }, [props.mountain, user]);

    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const [isTripModalOpen, setIsTripModalOpen] = useState(false);
    const [isCreateTripModalOpen, setIsCreateTripModalOpen] = useState(false);
    const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);

    const nodeRef = useRef(null);

  return (
    <div className={`mountain-card-visited-container mountain-card-visited-root-class-name`}>
      {isSaveModalOpen && <ModalSaveMountain isModalOpen={isSaveModalOpen} setIsModalOpen={setIsSaveModalOpen} setIsRatingModalOpen={setIsRatingModalOpen} wantVisit={wantToVisit} visited={visited} mountainName={props.mountain.name}/>}
      {isTripModalOpen && <ModalAddToTrip isModalOpen={isTripModalOpen} setIsModalOpen={setIsTripModalOpen} setIsCreateTripModalOpen={setIsCreateTripModalOpen} mountainName={props.mountain.name}/>}
      {isCreateTripModalOpen && <ModalCreateTrip isModalOpen={isCreateTripModalOpen} setIsModalOpen={setIsCreateTripModalOpen}/>}
      {isRatingModalOpen && <ModalRateMountain isModalOpen={isRatingModalOpen} setIsModalOpen={setIsRatingModalOpen} mountainName={props.mountain.name}/>}
      <div className="mountain-card-visited-container01">
        <div className="mountain-card-visited-container02">
          <div onClick={() => {navigate("/dashboard/" + mtnName)}}
            onMouseEnter={() => setAbove(true)}
            onMouseLeave={() => setAbove(false)}
            style={{
                backgroundImage: `url(${mtnImage})`
              }}
            className="mountain-card-visited-container03"
          >
            <div className="mountain-card-visited-container04">
            <svg
              viewBox="0 0 1024 1024"
              onClick={(e) => {e.stopPropagation();setIsSaveModalOpen(true)}}
              className="mountain-card-visited-icon"
            >
              <path stroke="black" strokeWidth="5%" fill={(visited || wantToVisit) ? "#00faff" : "#c8c8c880"} d="M665.143 73.143c8.571 0 17.143 1.714 25.143 5.143 25.143 9.714 41.143 33.143 41.143 58.857v736.571c0 25.714-16 49.143-41.143 58.857-8 3.429-16.571 4.571-25.143 4.571-17.714 0-34.286-6.286-47.429-18.286l-252-242.286-252 242.286c-13.143 12-29.714 18.857-47.429 18.857-8.571 0-17.143-1.714-25.143-5.143-25.143-9.714-41.143-33.143-41.143-58.857v-736.571c0-25.714 16-49.143 41.143-58.857 8-3.429 16.571-5.143 25.143-5.143h598.857z"></path>
            </svg>
            <svg onClick={(e) => {e.stopPropagation();setIsTripModalOpen(true)}}
                className="mountain-card-visited-icon"
                fill={trip ? "#00faff" : "#c8c8c880"}
                viewBox="0 0 24 24"
                stroke="black"
                strokeWidth="1.5"
            >
                    <path d="M22 16.21v-1.895L14 8V4a2 2 0 0 0-4 0v4.105L2 14.42v1.789l8-2.81V18l-3 2v2l5-2 5 2v-2l-3-2v-4.685l8 2.895z"></path>
            </svg>
        </div>
        <CSSTransition
        in={above}
        timeout={500}
        nodeRef={nodeRef}
        classNames="mountain"
        unmountOnExit
        >
              <div ref={nodeRef} className="mountain-card-visited-container05">
                <div className="mountain-card-visited-container06">
                  <span className="mountain-card-visited-text">
                    {displayMtnFacts.Vertical}
                  </span>
                  <div className="mountain-card-visited-container07">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="mountain-card-visited-icon07"
                    >
                      <path
                        d="M585.143 258.286l180.571 180.571-326.857 326.857-180.571-180.571zM464.571 817.714l353.143-353.143c14.286-14.286 14.286-37.143 0-51.429l-206.857-206.857c-13.714-13.714-37.714-13.714-51.429 0l-353.143 353.143c-14.286 14.286-14.286 37.143 0 51.429l206.857 206.857c6.857 6.857 16 10.286 25.714 10.286s18.857-3.429 25.714-10.286zM972.571 453.714l-518.286 518.857c-28.571 28-75.429 28-103.429 0l-72-72c42.857-42.857 42.857-112.571 0-155.429s-112.571-42.857-155.429 0l-71.429-72c-28.571-28-28.571-74.857 0-103.429l518.286-517.714c28-28.571 74.857-28.571 103.429 0l71.429 71.429c-42.857 42.857-42.857 112.571 0 155.429s112.571 42.857 155.429 0l72 71.429c28 28.571 28 75.429 0 103.429z"
                        className=""
                      ></path>
                    </svg>
                    <span className="mountain-card-visited-text01">
                      {displayMtnFacts.PassType}
                    </span>
                  </div>
                </div>
                <div className="mountain-card-visited-container08">
                  <span className="mountain-card-visited-text02">
                    {displayMtnFacts.Acres}
                  </span>
                  <div className="mountain-card-visited-container09">
                    <span className="mountain-card-visited-text03">
                      {displayMtnFacts.Lifts}
                    </span>
                  </div>
                </div>
                <div className="mountain-card-visited-container10">
                  <span className="mountain-card-visited-text04">
                    {displayMtnFacts.TotalRuns}
                  </span>
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="mountain-card-visited-icon09"
                  >
                    <path
                      d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                      className=""
                    ></path>
                  </svg>
                  <span className="mountain-card-visited-text05">
                    {displayMtnFacts.BeginnerRuns}
                  </span>
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="mountain-card-visited-icon11"
                  >
                    <path
                      d="M877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                      className=""
                    ></path>
                  </svg>
                  <span className="mountain-card-visited-text06">
                    {displayMtnFacts.IntermediateRuns}
                  </span>
                  <svg
                    viewBox="0 0 1024 1024"
                    className="mountain-card-visited-icon13"
                  >
                    <path
                      d="M512 0l-320 512 320 512 320-512z"
                      className=""
                    ></path>
                  </svg>
                  <span className="mountain-card-visited-text07">
                    {displayMtnFacts.ExpertRuns}
                  </span>
                </div>
              </div>
            </ CSSTransition>
          </div>
          <div className="mountain-card-visited-container11">
            <div className="mountain-card-visited-container12">
              <span className="mountain-card-visited-text08">
                {displayMtnFacts.Name}
              </span>
              <span className="mountain-card-visited-text09">
                {displayMtnFacts.Location}
              </span>
            </div>
            <div className="mountain-card-visited-container13">
              <div className="mountain-card-visited-container14">
                <span className="mountain-card-visited-text10">
                  Your Rating
                </span>
              </div>
              <div className="mountain-card-visited-container15">
                <svg
                  viewBox="0 0 1024 1024"
                  className="mountain-card-visited-icon15"
                >
                  <path
                    d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
                    className=""
                  ></path>
                </svg>
                <span className="mountain-card-visited-text11">
                  {displayMtnFacts.Rating}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mountain-card-visited-container16">
          <h3 className="mountain-card-visited-text12">Your visits</h3>
          <div className="mountain-card-visited-rating-box">
            { ratings.map(rate => (
                        <div className="mountain-card-visited-container17">
                        <span className="">{rate.date.slice(0,10)}</span>
                        <div className="mountain-card-visited-container18">
                            <svg
                            viewBox="0 0 1024 1024"
                            className="mountain-card-visited-icon17"
                            >
                            <path
                                d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
                                className=""
                            ></path>
                            </svg>
                            <span className="mountain-card-visited-text14">
                            {rate.rating.toFixed(2)}
                            </span>
                        </div>
                        </div>
            ))}
          </div>
          <svg onClick={() => setIsRatingModalOpen(true)} viewBox="0 0 1024 1024" className="mountain-card-visited-icon23">
            <path
              d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM554 298v172h172v84h-172v172h-84v-172h-172v-84h172v-172h84z"
              className=""
            ></path>
          </svg>
          <span className="">Add More</span>
        </div>
      </div>
    </div>
  )
}

MountainCardVisited.defaultProps = {}



export default MountainCardVisited
