import React from 'react';

import './rating-distribution.css'


// RatingBar component
const RatingBar = ({ stars, percentage }) => {
  return (
    <div className="rating-distribution-container01">
      <span className="rating-distribution-text">{stars} Star</span>
      <div className='rating-distribution-container02'>
        <div
            className='rating-distribution-bar'
          style={{
            width: `${percentage}%`,
          }}
        />
      </div>
      <span className="rating-distribution-text01">{percentage}%</span>
    </div>
  );
};

// Main component
const RatingDistribution = (props) => {
    function convertRatingsToPercentage(ratings) {
        // Initialize the star count dictionary
        const starCounts = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
        const totalRatings = ratings.length;

        if (totalRatings === 0){
            return []
        }
        
        // Count each star rating (rounding up)
        ratings.forEach(rating => {
            const roundedRating = Math.ceil(rating.rating);
            starCounts[roundedRating] += 1;
        });
        
        // Convert the star counts to percentage
        const starPercentages = [5,4,3,2,1].map(star => {
            return {
            stars: Number(star),
            percentage: Math.round((starCounts[star] / totalRatings) * 100),
            };
        });
        
        return starPercentages;
        }

  const ratingDistribution = convertRatingsToPercentage(props.ratings)
  return (
    <div className='rating-distribution-container'>
      {ratingDistribution.map((rating) => (
        <RatingBar
          key={rating.stars}
          stars={rating.stars}
          percentage={rating.percentage}
        />
      ))}
    </div>
  );
};

export default RatingDistribution;
