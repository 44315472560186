import React, { useState } from 'react'

import './modal-save-mountain.css'

import Modal from 'react-modal';
import { useEffect } from 'react';

import { authContext } from "../../../utils/useAuth";

import {updateProfile} from "../../../utils/apiHelper.js"

import LoadingIcon from './loading';


const ModalSaveMountain = (props) => {
    const [isCheckedWant, setIsCheckedWant] = useState(props.wantVisit);
    const [isCheckedVisited, setIsCheckedVisited] = useState(props.visited);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setIsCheckedVisited(props.visited)
        setIsCheckedWant(props.wantVisit)
    }, [props.visited, props.wantVisit])

    const authCont = React.useContext(authContext)
    const user = authCont.user;
    const login = authCont.login;
  

    const updateProfileProperty = async (property, value) => {
        setLoading(true)
        const {status, updatedUser} = await updateProfile(user.uid, property, value);
        if (status !== 200) {
          console.error("Something went wrong updating data :(")
        } else {
            login(updatedUser)
            props.setIsModalOpen(false)
            console.log(updatedUser)
        }
        setLoading(false)
      }
    
      const saveVisitChanges = async (visit, wantToVisit) => {
        let count = 0
        let name = props.mountainName
        if(visit && !user.visited){
          updateProfileProperty('visited', [name]);
          props.setIsRatingModalOpen(true)
        } else if (user.visited && visit && !user.visited.includes(name)) {
          updateProfileProperty('visited', [...user.visited, name]);
          props.setIsRatingModalOpen(true)
        } else if (user.visited && !visit && user.visited.includes(name)) {
          updateProfileProperty('visited', user.visited.filter(r => r !== name));
        } else {
            count += 1
        }
    
        if(wantToVisit && !user.wantToVisit){
          updateProfileProperty('wantToVisit', [name]);
        } else if (user.wantToVisit && wantToVisit && !user.wantToVisit.includes(name)) {
          updateProfileProperty('wantToVisit', [...user.wantToVisit, name]);
        } else if (user.wantToVisit && !wantToVisit && user.wantToVisit.includes(name)) {
          updateProfileProperty('wantToVisit', user.wantToVisit.filter(r => r !== name));
        } else {
            count += 1
        }

        if (count === 2 ) {
            props.setIsModalOpen(false)
        }
      }

  return (
    <Modal
    isOpen={props.isModalOpen}
    onRequestClose={() => props.setIsModalOpen(false)}
    shouldCloseOnOverlayClick={true}
    ariaHideApp={false}
    className="modal-save-mountain-container"
    >
    {loading ? <div className="modal-save-mountain-container1"><LoadingIcon/></div> :
        <div className="modal-save-mountain-container1">
            <button onClick={() => props.setIsModalOpen(false)} className="modal-save-mountain-button button">
            <svg viewBox="0 0 1024 1024" className="modal-save-mountain-icon">
                <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
            </svg>
            </button>
            <div className="modal-save-mountain-container2">
            <h1 className="modal-save-mountain-text">Save Mountain</h1>
            <svg viewBox="0 0 1024 1024" className="modal-save-mountain-icon2">
                <path d="M726 128q34 0 59 26t25 60v682l-298-128-298 128v-682q0-34 25-60t59-26h428z"></path>
            </svg>
            </div>
            <div className="modal-save-mountain-container3">
            <div className="modal-save-mountain-container4">
                <svg viewBox="0 0 1024 1024" className="modal-save-mountain-icon4">
                <path d="M598 256l384 512h-940l256-342 192 256 68-50-120-162z"></path>
                </svg>
                <span className="modal-save-mountain-text1">Visited</span>
            </div>
            <input
                type="checkbox"
                checked={isCheckedVisited}
                onChange={(event) => setIsCheckedVisited(event.target.checked)}
                className="modal-save-mountain-checkbox"
            />
            </div>
            <div className="modal-save-mountain-container5">
            <div className="modal-save-mountain-container6">
                <svg viewBox="0 0 1024 1024" className="modal-save-mountain-icon6">
                <path d="M598 256l384 512h-940l256-342 192 256 68-50-120-162z"></path>
                </svg>
                <span className="modal-save-mountain-text2">Want to visit</span>
            </div>
            <input
                type="checkbox"
                checked={isCheckedWant}
                onChange={(event) => setIsCheckedWant(event.target.checked)}
                className="modal-save-mountain-checkbox1"
            />
            </div>
            <button className="modal-save-mountain-button1 button" onClick={() => saveVisitChanges(isCheckedVisited, isCheckedWant)}>Save</button>
        </div>
    }
    </Modal>
  )
}

export default ModalSaveMountain
