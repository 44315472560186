import React, { useState } from 'react';

import { Helmet } from 'react-helmet'

import NavBarPublic from '../components/nav-bar-public'
import Stats from '../components/stats'
import Main from '../components/main'
import Hero from '../components/hero'
import PhonePoster from '../components/phone-poster'
import Footer from '../components/footer'

import { authContext } from "../../../utils/useAuth";

import './sign-up-page.css'

import {
    signInWithGoogle,
    registerWithEmailAndPassword,
    signInWithFacebook,
    signInWithApple
  } from '../../../firebase.js'

import { useNavigate, useLocation  } from "react-router-dom";

import LoadingIcon from "../components/loading"
import { useEffect } from 'react';


const SignUpPage = (props) => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [validAccount, setValid] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [loading, setLoading] = useState(false)

    function validateFields(eml, pass) {
        let isValid = true
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(eml) === false) {
            isValid = false
            setEmailError('Email is not valid')
        } else {
            setEmailError('')
        }

        if (pass.length < 6) {
            isValid = false
            setPasswordError('Password must be 6 or more characters')
        }  else {
            setPasswordError('')
        }

        return isValid
    }

    const authCont = React.useContext(authContext)
    const user = authCont.user;

    useEffect(() => {
        if(user){
            handleNav()
        }
    }, [user])


    const onChangeFirstName = event => {
        let cleanText = event.target.value.replace(/[^0-9A-Za-z-_!*]/g, '')
        setFirstName(cleanText)
        setFirstNameError('')
    }
    const onChangeLastName = event => {
        let cleanText = event.target.value.replace(/[^0-9A-Za-z-_!*]/g, '')
        setLastName(cleanText)
        setLastNameError('')
    }
    const onChangeEmail = event => {
        setEmail(event.target.value)
        setValid(validateFields(event.target.value, password))
    }
    const onChangePassword = event => {
        let cleanText = event.target.value.replace(/[^0-9A-Za-z#!*]/g, '')
        setPassword(cleanText)
        setValid(validateFields(email, cleanText))
    }

    const navigate = useNavigate();
    const { state } = useLocation();

    const handleSignUpEmail = async () =>{
        setLoading(true)
            try {
                let name = firstName + " " + lastName
                await registerWithEmailAndPassword(name, email, password)
                state?.path ? navigate('/profile-setup', {replace: true, state: {path: state.path}}) : navigate("/profile-setup")
            } catch (e) {
                if (e.code === 'auth/email-already-in-use') {
                    setEmailError('That email address is already in use!');
                }

                if (e.code === 'auth/invalid-email') {
                    setEmailError('That email address is invalid!');
                }
            }

    }

    const handleNav = () => {
        navigate(state?.path || '/explore', {replace: true})
    }

    const handleGoogleSignin = async () => {
        await signInWithGoogle()
        handleNav()
    }
    const handleFacebookSignin = async () => {
        await signInWithFacebook()
        handleNav()
    }
    const handleAppleSignin = async () => {
        await signInWithApple()
        handleNav()
    }


  return (
    <div className="sign-up-page-container">
      <Helmet>
        <title>SignUpPage - Alpinac</title>
        <meta property="og:title" content="SignUpPage - Alpinac" />
      </Helmet>
      <div id="top" className="sign-up-page-top-container">
        <NavBarPublic></NavBarPublic>
        {loading && <LoadingIcon /> }
        <div className="sign-up-page-hero">
          <div className="sign-up-page-content-container">
            <h1 className="sign-up-page-text">Create your free account</h1>
            <button onClick={() => handleGoogleSignin()} className="sign-up-page-button button">
              <img
                alt=""
                src={require("../../public/playground_assets/googleLogo.png")}
                className="sign-up-page-image"
              />
              <span className="sign-up-page-text01">
                <span>Continue with Google</span>
                <br></br>
              </span>
              <img
                alt=""
                src={require("../../public/playground_assets/googleLogo.png")}
                className="sign-up-page-image1"
              />
            </button>
            <button onClick={() => handleFacebookSignin()} className="sign-up-page-button1 button">
              <img
                alt=""
                src={require("../../public/playground_assets/f_logo_rgb-white_58-700h.png")}
                className="sign-up-page-image2"
              />
              <span className="sign-up-page-text04">
                <span>Continue with Facebook</span>
                <br></br>
              </span>
              <img
                alt=""
                src={require("../../public/playground_assets/googleLogo.png")}
                className="sign-up-page-image3"
              />
            </button>
            <button onClick={() => handleAppleSignin()} className="sign-up-page-button2 button">
              <img
                alt=""
                src={require("../../public/playground_assets/appleLogo.png")}
                className="sign-up-page-image4"
              />
              <span className="sign-up-page-text07">
                <span>Continue with Apple</span>
                <br></br>
              </span>
              <img
                alt=""
                src={require("../../public/playground_assets/googleLogo.png")}
                className="sign-up-page-image5"
              />
            </button>
            <div className="sign-up-page-container1">
              <div className="sign-up-page-container2"></div>
              <span className="sign-up-page-text10">
                <span className="sign-up-page-text11">or</span>
                <br></br>
              </span>
              <div className="sign-up-page-container3"></div>
            </div>
            <input
              type="text"
              name="FirstName"
              placeholder="First name"
              className="sign-up-page-textinput input"
              onChange={onChangeFirstName}
              value={firstName}
            />
            <input
              type="text"
              name="LastName"
              placeholder="Last name"
              className="sign-up-page-textinput1 input"
              onChange={onChangeLastName}
              value={lastName}
            />
            <input
              type="text"
              name="Email"
              placeholder="Email"
              className="sign-up-page-textinput2 input"
              onChange={onChangeEmail}
              value={email}
            />
            <input
              type="text"
              name="Password"
              placeholder="Password"
              className="sign-up-page-textinput3 input"
              onChange={onChangePassword}
              value={password}
            />
            <button onClick={() => handleSignUpEmail()} className="sign-up-page-button3 button">
              <span className="sign-up-page-text13">
                <span>Sign Up</span>
                <br></br>
              </span>
            </button>
            <span className="sign-up-page-text16">
              <span>Already have an account?</span>
              <span className="sign-up-page-text18"> Log in</span>
            </span>
            <span className="sign-up-page-text19">
              <span>
                By continuing to use Alpinac you agree to our Terms of Service
                and 
              </span>
              <span>Privacy Policy</span>
            </span>
          </div>
        </div>
      </div>
      <Stats></Stats>
      <Main></Main>
      <Hero></Hero>
      <PhonePoster></PhonePoster>
      <Footer></Footer>
      <a href="#top" className="sign-up-page-scroll-up-button button">
        <svg viewBox="0 0 658.2857142857142 1024" className="sign-up-page-icon">
          <path d="M614.286 749.714c0 4.571-2.286 9.714-5.714 13.143l-28.571 28.571c-3.429 3.429-8 5.714-13.143 5.714-4.571 0-9.714-2.286-13.143-5.714l-224.571-224.571-224.571 224.571c-3.429 3.429-8.571 5.714-13.143 5.714s-9.714-2.286-13.143-5.714l-28.571-28.571c-3.429-3.429-5.714-8.571-5.714-13.143s2.286-9.714 5.714-13.143l266.286-266.286c3.429-3.429 8.571-5.714 13.143-5.714s9.714 2.286 13.143 5.714l266.286 266.286c3.429 3.429 5.714 8.571 5.714 13.143zM614.286 530.286c0 4.571-2.286 9.714-5.714 13.143l-28.571 28.571c-3.429 3.429-8 5.714-13.143 5.714-4.571 0-9.714-2.286-13.143-5.714l-224.571-224.571-224.571 224.571c-3.429 3.429-8.571 5.714-13.143 5.714s-9.714-2.286-13.143-5.714l-28.571-28.571c-3.429-3.429-5.714-8.571-5.714-13.143s2.286-9.714 5.714-13.143l266.286-266.286c3.429-3.429 8.571-5.714 13.143-5.714s9.714 2.286 13.143 5.714l266.286 266.286c3.429 3.429 5.714 8.571 5.714 13.143z"></path>
        </svg>
      </a>
    </div>
  )
}

export default SignUpPage
