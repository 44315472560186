import reportWebVitals from './reportWebVitals';
import React from "react";
import ReactDOM from "react-dom/client";
import "./styles.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./utils/useAuth";


ReactDOM.createRoot(
    document.getElementById("root"),
  )
  .render(
    <React.StrictMode>
    <div className="yellow-border" />
    <div className="container">
      <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </div>
  </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
