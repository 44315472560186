import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet'

import NavBarPrivate from '../components/nav-bar-private'
import NavBarPublic from '../components/nav-bar-public'

import DashTrailAndStats from '../components/dash-trail-and-stats'
import DashLocalMountainReport from '../components/dash-local-mountain-report'
import Footer from '../components/footer'
import './mountain-dashboard.css'
import Reviews from '../components/reviews';

import { authContext } from "../../../utils/useAuth";
import {getMountainInfo} from "../../../utils/apiHelper.js"

import { imageExists } from '../../../utils/imgChecker';

import {
    useParams,
    useNavigate
  } from "react-router-dom";
import { getInfoDisplayReady } from '../../../utils/dataProcessors';
import Triangle from '../components/dash-run-difficulty-visual.js';

import {IMAGE_BASE_URL, IMAGE_THUMB_BASE_URL} from '../Constants.js';

import ModalSaveMountain from '../components/modal-save-mountain';
import ModalAddToTrip from '../components/modal-add-to-trip.js'
import ModalCreateTrip from '../components/modal-create-trip';
import ModalRateMountain from '../components/modal-rate-mountain';

const MountainDashboard = () => {
    let params = useParams();
    const navigate = useNavigate();

      // Get user and auth info
    const {user, authed} = React.useContext(authContext)

    const [loading, setLoading] = useState(true);
    const [wantToVisit, setWantToVisit] = useState(false)
    const [visited, setVisited] = useState(false)
    const [trip, setTrip] = useState(false)

    const [mtn, setMtn] = useState({})
  
    // Mountain Properties
    const [mtnImage, setImage] = useState('https://images.unsplash.com/photo-1600477114512-77ddbbe49e9e?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDYyfHx2YWlsJTIwbW91bnRhaW58ZW58MHx8fHwxNjY4Mjc1NTQ3&ixlib=rb-4.0.3&w=400')

    const [displayMtnFacts, setDisplayMtnFacts] = useState({})

    const fetchMountainData = async (mountain) => {
        try {
            let json = await getMountainInfo(mountain);
            console.log(json)
            return { success: true, data: json };
        } catch (error) {
            console.log(error);
            return { success: false };
        }
    }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let res = await fetchMountainData(params.id);
      if (res.success) {
        const mountain = res.data
        setDisplayMtnFacts(getInfoDisplayReady(mountain))

        setMtn(mountain)
    
        const validUrl = (bool) => {
            if (bool){
                setImage(mountain.imgThumb)
            }
        }
    
        (async () => {
          mountain.imgThumb && imageExists(mountain.imgThumb, validUrl)     
        })();

        setVisited(user?.visited && user.visited.includes(mountain.name))
        setWantToVisit(user?.wantToVisit && user.wantToVisit.includes(mountain.name))
        setTrip(user?.trips && Object.values(user.trips).some(trip => trip.mountains && trip.mountains.includes(mountain.name)))

        setLoading(false);
      }
    })();
  }, [params.id, user]);

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isTripModalOpen, setIsTripModalOpen] = useState(false);
  const [isCreateTripModalOpen, setIsCreateTripModalOpen] = useState(false);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);

    
    
  return (
    <div className="mountain-dashboard-container">
      <Helmet>
        <title>Mountain Dashboard - Alpinac</title>
        <meta property="og:title" content="Mountain Dashboard - Alpinac" />
      </Helmet>
      {!authed ?
          <NavBarPublic rootClassName="nav-bar-public-root-class-name"></NavBarPublic>
          :
          <NavBarPrivate rootClassName="nav-bar-private-root-class-name"></NavBarPrivate>
        }
        {authed && isSaveModalOpen && <ModalSaveMountain isModalOpen={isSaveModalOpen} setIsModalOpen={setIsSaveModalOpen} setIsRatingModalOpen={setIsRatingModalOpen} wantVisit={wantToVisit} visited={visited} mountainName={mtn.name}/>}
    {authed && isTripModalOpen && <ModalAddToTrip isModalOpen={isTripModalOpen} setIsModalOpen={setIsTripModalOpen} setIsCreateTripModalOpen={setIsCreateTripModalOpen} mountainName={mtn.name}/>}
    {authed && isCreateTripModalOpen && <ModalCreateTrip isModalOpen={isCreateTripModalOpen} setIsModalOpen={setIsCreateTripModalOpen}/>}
    {authed && isRatingModalOpen && <ModalRateMountain isModalOpen={isRatingModalOpen} setIsModalOpen={setIsRatingModalOpen} mountainName={mtn.name} updateAllMtnRating={false}/>}    
      <div className="mountain-dashboard-header-container">
        <div className="mountain-dashboard-inner-header-container">
          <div className="mountain-dashboard-left">
            <svg onClick={() => navigate(-1)} viewBox="0 0 1024 1024" className="mountain-dashboard-icon">
              <path d="M512 386.517v-109.184c0-10.923-4.181-21.845-12.501-30.208-8.32-8.32-19.243-12.459-30.165-12.459s-21.845 4.139-30.165 12.459l-268.501 264.875 268.501 264.832c8.32 8.32 19.243 12.501 30.165 12.501s21.845-4.181 30.165-12.501 12.501-19.285 12.501-30.165v-106.197c117.333 2.901 245.547 24.149 341.333 170.197v-42.667c0-197.675-149.333-360.235-341.333-381.483z"></path>
            </svg>
            <div className="mountain-dashboard-container01">
              <span className="mountain-dashboard-text">{params.id}</span>
              <div className="mountain-dashboard-container02">
                <span className="mountain-dashboard-text01">{displayMtnFacts.Location}</span>
                <div className="mountain-dashboard-container03">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="mountain-dashboard-icon02"
                  >
                    <path d="M1024 397.050l-353.78-51.408-158.22-320.582-158.216 320.582-353.784 51.408 256 249.538-60.432 352.352 316.432-166.358 316.432 166.358-60.434-352.352 256.002-249.538z"></path>
                  </svg>
                  <span className="mountain-dashboard-text02">{displayMtnFacts.Rating}</span>
                </div>
              </div>
            </div>
            <svg viewBox="0 0 1024 1024" className="mountain-dashboard-icon04">
              <path d="M298.667 158.848v584.405l-213.333 121.899v-584.405zM725.333 865.152v-584.405l213.333-121.899v584.363zM662.827 976.427c3.499 1.835 7.253 3.2 11.264 4.011 2.816 0.597 5.717 0.896 8.576 0.896 7.381 0 14.677-1.963 21.163-5.632l0.64-0.384 298.027-170.283c13.696-7.808 21.419-22.101 21.504-37.035v-682.667c0-23.552-19.115-42.667-42.667-42.667-7.808 0-15.104 2.091-21.163 5.632l-278.827 159.317-320.128-160.085c-3.541-1.792-7.296-3.2-11.264-4.011-2.859-0.555-5.76-0.853-8.619-0.853-7.424 0-14.72 1.963-21.163 5.632l-0.64 0.341-298.027 170.325c-13.696 7.808-21.419 22.101-21.504 37.035v682.667c0 23.552 19.115 42.667 42.667 42.667 7.808 0 15.104-2.091 21.163-5.632l278.827-159.317zM640 282.368v587.264l-256-128v-587.264z"></path>
            </svg>
          </div>
          <div className="mountain-dashboard-right">
            <div className="mountain-dashboard-container04">
              <svg
                viewBox="0 0 1024 1024"
                className="mountain-dashboard-icon06"
                onClick={(e) => authed && setIsSaveModalOpen(true)}
              >
                <path d="M726 128q34 0 59 26t25 60v682l-298-128-298 128v-682q0-34 25-60t59-26h428z"></path>
              </svg>
              <span className="mountain-dashboard-text03">Visited</span>
            </div>
            <div className="mountain-dashboard-container05">
              <svg
                viewBox="0 0 1024 1024"
                className="mountain-dashboard-icon08"
                onClick={(e) => authed && setIsTripModalOpen(true)}
              >
                <path d="M895.488 528.896l-212.821-121.643v-204.587c0-82.347-66.987-149.333-149.333-149.333s-149.333 66.987-149.333 149.333v204.587l-212.821 121.643c-56.192 32.085-79.573 101.163-54.443 160.811 25.131 59.691 90.965 91.093 153.131 73.387l114.133-32.64v35.755l-37.291 29.824c-51.115 40.917-63.147 113.792-27.861 168.917 35.285 55.168 106.411 74.795 165.077 45.483 0.171-0.043 21.333-7.808 49.408-7.808s49.237 7.765 49.408 7.808c58.667 29.312 129.792 9.685 165.077-45.483 35.285-55.125 23.253-128-27.861-168.917l-37.291-29.824v-35.755l114.176 32.64c62.165 17.707 128-13.696 153.131-73.387 25.131-59.691 1.749-128.725-54.485-160.811zM871.339 656.555c-8.405 19.883-30.336 30.421-51.029 24.448l-222.976-63.659v189.909l69.333 55.467c17.024 13.611 21.035 37.931 9.301 56.32-11.733 18.347-35.328 24.875-55.040 15.147-0.341-0.171-35.157-16.853-87.595-16.853s-87.253 16.683-87.595 16.853c-19.712 9.685-43.307 3.157-55.040-15.147-11.733-18.389-7.765-42.667 9.301-56.32l69.333-55.467v-189.909l-222.976 63.701c-20.693 5.973-42.624-4.565-51.029-24.448-8.363-19.883-0.597-42.88 18.176-53.589l255.829-146.219v-254.123c0-35.285 28.715-64 64-64s64 28.715 64 64v254.123l255.829 146.176c18.773 10.709 26.539 33.707 18.176 53.589z"></path>
                <path d="M554.667 192c0 11.782-9.551 21.333-21.333 21.333-11.782 0-21.333-9.551-21.333-21.333 0-11.782 9.551-21.333 21.333-21.333 11.782 0 21.333 9.551 21.333 21.333z"></path>
              </svg>
              <span className="mountain-dashboard-text04">Trips</span>
            </div>
            <div className="mountain-dashboard-container06">
              <svg
                viewBox="0 0 1024 1024"
                className="mountain-dashboard-icon11"
                onClick={(e) => authed && setIsRatingModalOpen(true)}
              >
                <path d="M512 658l160 96-42-182 142-124-188-16-72-172-72 172-188 16 142 124-42 182zM938 394l-232 202 70 300-264-160-264 160 70-300-232-202 306-26 120-282 120 282z"></path>
              </svg>
              <span className="mountain-dashboard-text05">Rate</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mountain-dashboard-dash">
        <div className="mountain-dashboard-dash1">
          <div className="mountain-dashboard-container07">
            <DashTrailAndStats img={IMAGE_BASE_URL + displayMtnFacts.imgFull} thumb={IMAGE_THUMB_BASE_URL + displayMtnFacts.imgFull} vert={displayMtnFacts.Vertical} baseEle={displayMtnFacts.BaseElevation} skiableArea={displayMtnFacts.Acres} nightSkiing={mtn.nightSkiing}/>
            <div className="mountain-dashboard-container08">
              <div className="mountain-dashboard-container09">
              <Triangle percentages={[displayMtnFacts.TriangleBeginnerRuns,displayMtnFacts.TriangleIntermediateRuns,displayMtnFacts.TriangleExpertRuns]}/>
                <span className="mountain-dashboard-text06">
                    {displayMtnFacts.TotalRuns} Total Runs
                </span>
              </div>
              <div className="mountain-dashboard-container10">
                <span className="mountain-dashboard-text07">{displayMtnFacts.ExpertRuns} Blacks</span>
                <span className="mountain-dashboard-text08">{displayMtnFacts.IntermediateRuns} Blues</span>
                <span className="mountain-dashboard-text09">{displayMtnFacts.BeginnerRuns} Greens</span>
              </div>
            </div>
          </div>
          <div className="mountain-dashboard-container11">
            <DashLocalMountainReport overview={mtn.additionalInfo ? mtn.additionalInfo.mtnReport.Overview : null} />
          </div>
        </div>
      </div>
      <div className="mountain-dashboard-dash2">
        <Reviews reviews={mtn?.additionalInfo?.reviews} ratings={mtn?.additionalInfo?.ratings} rating={mtn.rating} numberOfRatings={mtn.ratingCount} user={user}></Reviews>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default MountainDashboard
