import React from 'react'

import { Helmet } from 'react-helmet'

import {
    Link,
    useNavigate  } from "react-router-dom";

import NavBarPublic from '../components/nav-bar-public'
import NavBarPrivate from '../components/nav-bar-private'
import SolidButton from '../components/solid-button'
import Stats from '../components/stats'
import Main from '../components/main'
import Hero from '../components/hero'
import PhonePoster from '../components/phone-poster'
import Footer from '../components/footer'
import './landing-page.css'

const LandingPage = (props) => {
    const [inputValue, setInputValue] = React.useState("");
    const navigate = useNavigate();

    const onChangeHandler = event => {
    setInputValue(event.target.value);
    };

    const onSubmitHandler = event => {
        navigate("/explore/" + inputValue)
    };

  return (
    <div className="landing-page-container">
      <Helmet>
        <title>Alpinac</title>
        <meta property="og:title" content="Alpinac" />
        <meta name="description" content="Your go-to destination for finding the ideal ski resort for your next winter getaway. With our user-friendly search function, you can quickly and easily find ski resorts that match your preferences and ability. We provide detailed information on ski resorts, giving you everything you need to plan your perfect ski trip. Start exploring our website today and get ready to hit the slopes!" />
      </Helmet>
      <div className="landing-page-top-container">
      {!props.authed ?
          <NavBarPublic rootClassName="nav-bar-public-root-class-name"></NavBarPublic>
          :
          <NavBarPrivate rootClassName="nav-bar-private-root-class-name"></NavBarPrivate>
        }
        <div className="landing-page-hero">
          <div className="landing-page-content-container">
            <h1 className="landing-page-text Heading">
              Find your next mountain
            </h1>
            <div className="landing-page-container1">
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="landing-page-icon"
              >
                <path d="M658.286 475.429c0-141.143-114.857-256-256-256s-256 114.857-256 256 114.857 256 256 256 256-114.857 256-256zM950.857 950.857c0 40-33.143 73.143-73.143 73.143-19.429 0-38.286-8-51.429-21.714l-196-195.429c-66.857 46.286-146.857 70.857-228 70.857-222.286 0-402.286-180-402.286-402.286s180-402.286 402.286-402.286 402.286 180 402.286 402.286c0 81.143-24.571 161.143-70.857 228l196 196c13.143 13.143 21.143 32 21.143 51.429z"></path>
              </svg>
              <form onSubmit={onSubmitHandler}>
              <input
                type="text"
                placeholder="Search by name or location"
                className="landing-page-textinput input"
                onChange={onChangeHandler}
                value={inputValue}
              />
              </form>
              <Link
                to={"/explore/" + inputValue}
                className="landing-page-link"
              >
                <svg viewBox="0 0 1024 1024" className="landing-page-icon2">
                  <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
              </Link>
            </div>
            <Link to={"/explore"}>
            <SolidButton
              button="Explore all mountains"
              rootClassName="solid-button-root-class-name"
            ></SolidButton>
            </Link>
          </div>
        </div>
      </div>
      <Stats></Stats>
      <Main></Main>
      <Hero></Hero>
      <PhonePoster></PhonePoster>
      <Footer></Footer>
    </div>
  )
}

export default LandingPage
