import React, {useState, useEffect} from 'react'

import './modal-create-trip.css'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Modal from 'react-modal';

import { authContext } from "../../../utils/useAuth";

import {updateProfile} from "../../../utils/apiHelper.js"
import LoadingIcon from './loading';

const ModalCreateTrip = (props) => {

  const [tripName, setTripName] = useState("")
  const [notes, setNotes] = useState("") 
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [loading, setLoading] = useState(false)

  const authCont = React.useContext(authContext)
  const user = authCont.user;
  const login = authCont.login;

  useEffect(() => {
    setNotes("")
    setDateRange([null, null])
    setTripName("")
  }, [])


  const updateProfileProperty = async (property, value) => {
      setLoading(true)
      const {status, updatedUser} = await updateProfile(user.uid, property, value);
      if (status !== 200) {
        console.error("Something went wrong updating data :(")
      } else {
          login(updatedUser)
          props.setIsModalOpen(false)
          console.log(updatedUser)
      }
      setLoading(false)
    }
  
    const saveNewTrip = async () => {
        console.log(!user.trips)
        if (!tripName || !startDate || !endDate || !notes){
            alert("Ensure all fields are completed")
        } else if (user.trips && user.trips.some(t => t.name === tripName)) {
            alert("Trip name must be unique")
        }
        else if (!user.trips){
            updateProfileProperty('trips', [{name: tripName, dates: dateRange, notes: notes}]);
        } else {
            updateProfileProperty('trips', [...user.trips, {name: tripName, dates: dateRange, notes: notes}])
        }
    }

  return (
    <Modal
    isOpen={props.isModalOpen}
    onRequestClose={() => props.setIsModalOpen(false)}
    shouldCloseOnOverlayClick={true}
    ariaHideApp={false} 
    className="modal-create-trip-container"
    overlayClassName="Overlay">
        {loading ? <div className="modal-create-trip-container1"><LoadingIcon/></div> :
      <div className="modal-create-trip-container1">
        <button onClick={() => props.setIsModalOpen(false)} className="modal-create-trip-button button">
          <svg viewBox="0 0 1024 1024" className="modal-create-trip-icon">
            <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
          </svg>
        </button>
        <div className="modal-create-trip-container2">
          <h1 className="modal-create-trip-text">Create a trip</h1>
          <svg viewBox="0 0 822.272 1024" className="modal-create-trip-icon2">
            <path d="M786.286 91.429c36.571 36.571 0 128-54.857 182.857l-92 92 91.429 397.714c1.714 6.857-1.143 14.286-6.857 18.857l-73.143 54.857c-2.857 2.286-6.857 3.429-10.857 3.429-1.143 0-2.286 0-4-0.571-5.143-1.143-9.714-4-12-9.143l-159.429-290.286-148 148 30.286 110.857c1.714 6.286 0 12.571-4.571 17.714l-54.857 54.857c-3.429 3.429-8.571 5.143-13.143 5.143h-1.143c-5.714-0.571-10.286-2.857-13.714-7.429l-108-144-144-108c-4.571-2.857-6.857-8-7.429-13.143s1.714-10.286 5.143-14.286l54.857-55.429c3.429-3.429 8.571-5.143 13.143-5.143 1.714 0 3.429 0 4.571 0.571l110.857 30.286 148-148-290.286-159.429c-5.143-2.857-8.571-8-9.714-13.714-0.571-5.143 1.143-11.429 5.143-15.429l73.143-73.143c4.571-4 11.429-6.286 17.143-4.571l380 90.857 91.429-91.429c54.857-54.857 146.286-91.429 182.857-54.857z"></path>
          </svg>
        </div>
        <div className="modal-create-trip-container3">
          <div className="modal-create-trip-container4">
            <span className="modal-create-trip-text1">Trip Name</span>
          </div>
          <input
            type="text"
            placeholder="College Ski Trip!"
            className="modal-create-trip-textinput input"
            value={tripName}
            onChange={(e) => setTripName(e.target.value)}
            />
          <div className="modal-create-trip-container5">
            <span className="modal-create-trip-text2">Dates</span>
          </div>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
                setDateRange(update);
            }}
            isClearable={true}
            className="modal-create-trip-date-picker"
            />
          <div className="modal-create-trip-container6">
            <span className="modal-create-trip-text3">Notes</span>
          </div>
          <textarea
            placeholder="Ski trip with college friends"
            className="modal-create-trip-textarea textarea"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          ></textarea>
        </div>
        <button className="modal-create-trip-button1 button" onClick={() => saveNewTrip()}>Save</button>
      </div>
}
    </Modal>
  )
}

export default ModalCreateTrip
