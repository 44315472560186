import React from 'react'

import PropTypes from 'prop-types'

import './dash-trail-and-stats.css'
import ModalFullMap from './dash-full-size-map'
import { useState } from 'react'



const DashTrailAndStats = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <div className="dash-trail-and-stats-container">
        <ModalFullMap img={props.img} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
      <div className="dash-trail-and-stats-container1" onClick={() => setIsModalOpen(true)}         style={{
            backgroundImage: `url(${props.thumb})`
          }}>
        <svg viewBox="0 0 1024 1024" className="dash-trail-and-stats-icon">
          <path d="M1024 0h-416l160 160-192 192 96 96 192-192 160 160z"></path>
          <path d="M1024 1024v-416l-160 160-192-192-96 96 192 192-160 160z"></path>
          <path d="M0 1024h416l-160-160 192-192-96-96-192 192-160-160z"></path>
          <path d="M0 0v416l160-160 192 192 96-96-192-192 160-160z"></path>
        </svg>
      </div>
      <span className="dash-trail-and-stats-text">{props.text}</span>
      <span className="dash-trail-and-stats-text01">
        <span className="dash-trail-and-stats-text02">Vertical &gt;</span>
        <span className="dash-trail-and-stats-text03"> {props.vert}</span>
      </span>
      <span className="dash-trail-and-stats-text04">
        <span className="dash-trail-and-stats-text05">Base elevatio</span>
        <span className="dash-trail-and-stats-text06">n</span>
        <span className="dash-trail-and-stats-text07"> &gt;</span>
        <span className="dash-trail-and-stats-text08"> {props.baseEle} </span>
      </span>
      <span className="dash-trail-and-stats-text09">
        <span className="dash-trail-and-stats-text10">Skiable area &gt;</span>
        <span className="dash-trail-and-stats-text11">{props.skiableArea}</span>
      </span>
      {props.nightSkiing &&
      <span className="dash-trail-and-stats-text12">
        <span className="dash-trail-and-stats-text13">Night skiing &gt;</span>
        <span className="dash-trail-and-stats-text14"> {props.nightSkiing}</span>
      </span>
      }
    </div>
  )
}

export default DashTrailAndStats
