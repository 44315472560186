import React, {useEffect} from "react";

import {getAuth} from "firebase/auth";

import { getUserData } from "./apiHelper";

export const authContext = React.createContext();

function useAuth() {
  const [authed, setAuthed] = React.useState(false);
  const [user, setUser] = React.useState(null)

  return {
    authed,
    user,
    login(user) {
      return new Promise((res) => {
        setAuthed(true);
        setUser(user);
        res();
      });
    },
    logout() {
      return new Promise((res) => {
        setAuthed(false);
        setUser(null)
        res();
      });
    }
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  useEffect(() => {
    let unsubscribe = null

    try {
        let authf = getAuth();
        unsubscribe = authf.onAuthStateChanged(async (user) => {
            if (user){
                const dbUser = await getUserData(user.uid);
                if (dbUser){
                    auth.login(dbUser)
                } else {
                    auth.login(user)
                }
            } else{
                auth.logout()
            }
        })
    } catch (e) {
        // Restoring token failed
        console.log("Error getting auth")
    }

    if (unsubscribe) {
        return unsubscribe;
    }
}, []);

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
