import React, {useState} from 'react'

import './modal-rate-mountain.css'

import { Rating } from 'react-simple-star-rating'

import Modal from 'react-modal';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import LoadingIcon from './loading';

import { authContext } from "../../../utils/useAuth";

import {updateProfile, updateMountainInfoAsync} from "../../../utils/apiHelper.js";


const ModalRateMountain = (props) => {

    const [rating, setRating] = useState(0)
    const [startDate, setStartDate] = useState(new Date());
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState("");

    const authCont = React.useContext(authContext)
    const user = authCont.user;
    const login = authCont.login;

    const onChangeHandler = event => {
        setInputValue(event.target.value);
    };


    const handleRating = (rate) => {
        setRating(rate)
      }

    const updateMountainInfo = async () => {
        let newRating = {date: startDate, rating: rating, abilityLevel: user.abilityLevel}
        let newReview = null
        if (inputValue !== ""){
            newReview = {date: startDate, review: inputValue, rating:rating, abilityLevel: user.abilityLevel, rideTypes: user.rideType, name: user.name}
        }
        const {status} = await updateMountainInfoAsync(newRating, newReview, props.mountainName)
        if (status !== 200) {
            console.error("Something went wrong updating data :(")
            return false
        }
        return true
    }

    const addRating = async (userRatings) => {
        setLoading(true)
        const globalUpdate = await updateMountainInfo()
        if (globalUpdate){
            // TODO update allMountains with new mountain rating if on explore page
            if (props.updateAllMtnRating) {
                props.updateAllMtnRating(props.mountainName, rating)
            }
            const {status, updatedUser} = await updateProfile(user.uid, 'ratings', userRatings);
            if (status !== 200) {
                console.error("Something went wrong updating data :(")
            } else {
                login(updatedUser)
                props.setIsModalOpen(false)
                console.log(updatedUser)
            }
        }
        setLoading(false)
    }

    const saveRating = async () => {
        if (!rating || !startDate){
            alert("Ensure both date and rating are completed")
        } else if (!user.ratings) {
           let newRatings = {}
           newRatings[props.mountainName] = [{date: startDate, rating: rating, review: inputValue}]
           addRating(newRatings)
        } else if (!(props.mountainName in user.ratings)){
            let newRatings = user.ratings
            newRatings[props.mountainName] = [{date: startDate, rating: rating, review: inputValue}]
            addRating(newRatings)
        } else {
            let newRatings = user.ratings
            newRatings[props.mountainName] = [...newRatings[props.mountainName], {date: startDate, rating: rating, review: inputValue}]
            addRating(newRatings)
        }
    }

  return (
    <Modal
    isOpen={props.isModalOpen}
    onRequestClose={() => props.setIsModalOpen(false)}
    shouldCloseOnOverlayClick={true}
    ariaHideApp={false}
    className="modal-rate-mountain-container"
    >
         {loading ? <div className="modal-rate-mountain-container1"><LoadingIcon/></div> :
      <div className="modal-rate-mountain-container1">
        <button onClick={() => props.setIsModalOpen(false)} className="modal-rate-mountain-button button">
          <svg viewBox="0 0 1024 1024" className="modal-rate-mountain-icon">
            <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
          </svg>
        </button>
        <div className="modal-rate-mountain-container2">
          <h1 className="modal-rate-mountain-text">Add Rating</h1>
          <svg
            viewBox="0 0 950.8571428571428 1024"
            className="modal-rate-mountain-icon02"
          >
            <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
          </svg>
        </div>
        <div className="modal-rate-mountain-container3">
        <Rating
        onClick={handleRating}
        allowFraction={true}
        transition={true}
      />
        </div>
        <div className="modal-rate-mountain-container4">
          <div className="modal-rate-mountain-container5">
            <svg viewBox="0 0 1024 1024" className="modal-rate-mountain-icon14">
              <path d="M598 256l384 512h-940l256-342 192 256 68-50-120-162z"></path>
            </svg>
            <span className="modal-rate-mountain-text1">Date of visit</span>
          </div>
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
          <div className="modal-rate-mountain-container6">
            <svg viewBox="0 0 1024 1024" className="modal-rate-mountain-icon16">
              <path d="M598 256l384 512h-940l256-342 192 256 68-50-120-162z"></path>
            </svg>
            <span className="modal-rate-mountain-text2">Add review</span>
          </div>
          <textarea
            placeholder="Great snow and fun apres!"
            className="modal-rate-mountain-textarea textarea"
            onChange={onChangeHandler}
            value={inputValue}
          ></textarea>
        </div>
        <button onClick={() => saveRating()} className="modal-rate-mountain-button6 button">Save</button>
      </div>
        }
    </Modal>
  )
}

export default ModalRateMountain
