import React from 'react';
import NavBarPrivate from '../components/nav-bar-private';
import './profile-page.css';

import { authContext } from "../../../utils/useAuth";
import DefaultProfilePic from '../components/default-profile-pic';

import { logout } from '../../../firebase.js'
import { Link } from 'react-router-dom';
import Footer from '../components/footer';


const ProfilePage = () => {

    const authCont = React.useContext(authContext)
    const logoutContext = authCont.logout;
    const user = authCont.user;

    const handleLogout = () => {
        logout()
        logoutContext()
    }


    
      return (
        <div>
        <NavBarPrivate />
        <div className="profile-container">
          <div className="profile-picture-container">
            {/* <img src="" alt="Profile" className="profile-picture" /> */}
            <DefaultProfilePic rideType={user.rideType} ability={user.abilityLevel} />
          </div>
          <div className="profile-details">
            <h1 className="profile-name">{user.name ? user.name : user.email}</h1>
            <p className="profile-info">Number of ratings: {user.ratings ? Object.values(user.ratings).map(mtn => Object.keys(mtn).length).reduce((partialSum, a) => partialSum + a, 0) : 0}</p>
            <p className="profile-info">Mountains visited: {user.visited ? Object.values(user.visited).length : 0}</p>
            <p className="profile-info">Ski Trips Saved: {user.trips ? Object.values(user.trips).length : 0}</p>
            <hr className="profile-divider" />
            <p className="profile-bio"></p>
            <Link className="profile-setup-button" to="/profile-edit">Edit Profile</Link>

            <button className="logout-button" onClick={() => handleLogout()}>Logout</button>
          </div>
        </div>
        <Footer/>
        </div>
      );
    }

export default ProfilePage;
