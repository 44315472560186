import React, { useState } from 'react'

import { Helmet } from 'react-helmet'

import NavBarPublic from '../components/nav-bar-public'
import Stats from '../components/stats'
import Main from '../components/main'
import Hero from '../components/hero'
import PhonePoster from '../components/phone-poster'
import Footer from '../components/footer'
import './login-page.css'

import {
    signInWithGoogle,
    logInWithEmailAndPassword,
    signInWithFacebook,
    signInWithApple,
    sendPasswordReset
  } from '../../../firebase.js'

  import { useNavigate, useLocation  } from "react-router-dom";

  import { authContext } from "../../../utils/useAuth";



const LoginPage = (props) => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState("")

    const onChangeEmail = event => {
        setEmail(event.target.value)
    }
    const onChangePassword = event => {
        let cleanText = event.target.value.replace(/[^0-9A-Za-z#!*]/g, '')
        setPassword(cleanText)
    }

    async function loginWithUsername() {
        try {
            logInWithEmailAndPassword(email, password)
            handleNav()
        } catch (e) {
            setLoginError('Incorrect Username or Password')
        }
    }

    const authCont = React.useContext(authContext)
    const login = authCont.login;

    const handleNav = () => {
        navigate(state?.path || '/explore', {replace: true})
    }

    const handleGoogleSignin = async () => {
        await signInWithGoogle()
        handleNav()
    }
    const handleFacebookSignin = async () => {
        await signInWithFacebook()
        handleNav()
    }
    const handleAppleSignin = async () => {
        await signInWithApple()
        handleNav()
    }

  return (
    <div className="login-page-container">
      <Helmet>
        <title>LoginPage - Alpinac</title>
        <meta property="og:title" content="LoginPage - Alpinac" />
      </Helmet>
      <div id="top" className="login-page-top-container">
        <NavBarPublic></NavBarPublic>
        <div className="login-page-hero">
          <div className="login-page-content-container">
            <h1 className="login-page-text">Welcome Back</h1>
            <button onClick={() => handleGoogleSignin()} className="login-page-button button">
              <img
                alt="google"
                src={require("../../public/playground_assets/googleLogo.png")}
                className="login-page-image"
              />
              <span className="login-page-text01">
                <span>Continue with Google</span>
                <br></br>
              </span>
              <img
                alt=""
                src={require("../../public/playground_assets/btn_google_dark_normal_ios.svg")}
                className="login-page-image1"
              />
            </button>
            <button onClick={() => handleFacebookSignin()} className="login-page-button1 button">
              <img
                alt=""
                src={require("../../public/playground_assets/f_logo_rgb-white_58-700h.png")}
                className="login-page-image2"
              />
              <span className="login-page-text04">
                <span>Continue with Facebook</span>
                <br></br>
              </span>
              <img
                alt=""
                src={require("../../public/playground_assets/btn_google_dark_normal_ios.svg")}
                className="login-page-image3"
              />
            </button>
            <button onClick={() => handleAppleSignin()} className="login-page-button2 button">
              <img
                alt=""
                src={require("../../public/playground_assets/appleLogo.png")}
                className="login-page-image4"
              />
              <span className="login-page-text07">
                <span>Continue with Apple</span>
                <br></br>
              </span>
              <img
                alt=""
                src={require("../../public/playground_assets/btn_google_dark_normal_ios.svg")}
                className="login-page-image5"
              />
            </button>
            <div className="login-page-container1">
              <div className="login-page-container2"></div>
              <span className="login-page-text10">
                <span className="login-page-text11"> or </span>
                <br></br>
              </span>
              <div className="login-page-container3"></div>
            </div>
            <input
              type="text"
              name="Email"
              placeholder="Email"
              className="login-page-textinput input"
              value={email}
              onChange={onChangeEmail}
            />
            <input
              type="text"
              name="Password"
              placeholder="Password"
              className="login-page-textinput1 input"
              value={password}
              onChange={onChangePassword}
            />
            <span onClick={() => sendPasswordReset(email)} style={{ cursor: 'pointer', fontSize: '12px', marginTop: '6px' }}>Reset Email</span>
            <button onClick={() => loginWithUsername()} className="login-page-button3 button">
              <span className="login-page-text13">
                <span>Login</span>
                <br></br>
              </span>
            </button>
            <span className="login-page-text16">
              <span>Don&apos;t have an account?</span>
              <span className="login-page-text18"> Sign up</span>
            </span>
            <span className="login-page-text19">
              <span>
                By continuing to use Alpinac you agree to our Terms of Service
                and 
              </span>
              <span>Privacy Policy</span>
            </span>
          </div>
        </div>
      </div>
      <Stats></Stats>
      <Main></Main>
      <Hero></Hero>
      <PhonePoster></PhonePoster>
      <Footer></Footer>
      <a href="#top" className="login-page-scroll-up-button button">
        <svg viewBox="0 0 658.2857142857142 1024" className="login-page-icon">
          <path d="M614.286 749.714c0 4.571-2.286 9.714-5.714 13.143l-28.571 28.571c-3.429 3.429-8 5.714-13.143 5.714-4.571 0-9.714-2.286-13.143-5.714l-224.571-224.571-224.571 224.571c-3.429 3.429-8.571 5.714-13.143 5.714s-9.714-2.286-13.143-5.714l-28.571-28.571c-3.429-3.429-5.714-8.571-5.714-13.143s2.286-9.714 5.714-13.143l266.286-266.286c3.429-3.429 8.571-5.714 13.143-5.714s9.714 2.286 13.143 5.714l266.286 266.286c3.429 3.429 5.714 8.571 5.714 13.143zM614.286 530.286c0 4.571-2.286 9.714-5.714 13.143l-28.571 28.571c-3.429 3.429-8 5.714-13.143 5.714-4.571 0-9.714-2.286-13.143-5.714l-224.571-224.571-224.571 224.571c-3.429 3.429-8.571 5.714-13.143 5.714s-9.714-2.286-13.143-5.714l-28.571-28.571c-3.429-3.429-5.714-8.571-5.714-13.143s2.286-9.714 5.714-13.143l266.286-266.286c3.429-3.429 8.571-5.714 13.143-5.714s9.714 2.286 13.143 5.714l266.286 266.286c3.429 3.429 5.714 8.571 5.714 13.143z"></path>
        </svg>
      </a>
    </div>
  )
}

export default LoginPage
