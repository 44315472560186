import React from 'react'

import './stats.css'

const Stats = () => {
  return (
    <div className="stats-stats">
      <div className="stats-stat">
        <h1 className="stats-text">2500+</h1>
        <h3 className="stats-subtext">Ski resorts around the world</h3>
        <span className="stats-description">Find the perfect ski resort for your ski or snowboard ability</span>
      </div>
      <div className="stats-stat">
        <h1 className="stats-text">19 Countries</h1>
        <h3 className="stats-subtext">Countries across 4 continents</h3>
        <span className="stats-description">Filter by location and find ski resorts closest to you</span>
      </div>
      <div className="stats-stat">
        <h1 className="stats-text">30,000+</h1>
        <h3 className="stats-subtext">Combined ski runs</h3>
        <span className="stats-description">Quickly and easily view ski resort difficulty by using key stats like vertical drop, skiable acreage, and percentage green, blue, or black diamond runs</span>
      </div>
      <div className="stats-stat">
        <h1 className="stats-text">4</h1>
        <h3 className="stats-subtext">Ski pass types</h3>
        <span className="stats-description">Filter resorts by Indy, Mountain Collective, Ikon, or Epic pass</span>
      </div>
    </div>
  )
}


export default Stats
