import React, { useEffect, useState } from 'react'

import './explore-page-selector.css'

function createArray(n, x) {
    console.log(n)
    console.log(x)
    let arr = Array.from({ length: 7 }, (_, i) => i + 1)
    arr[6] = n
    if (x <= 4){
        arr[5] = -1
        return arr
    }
    if (x >= n-3) {
        arr = [1, -1, n-4, n-3, n-2, n-1, n]
        return arr
    }

    return [1, -1, x-1, x, x +1, -1, n]
    

}

const ExplorePageSelector = (props) => {

  const [displayNums, setDisplayNums] = useState([])

  useEffect(()=>{
    if (props.numPages <= 7){
        setDisplayNums(Array.from({ length: props.numPages }, (_, i) => i + 1))
    } else {
        console.log("test")
        setDisplayNums(createArray(props.numPages, props.currentPage))
    }
  }, [props.currentPage, props.numPages])
  
  return (
    <div className="result-page-selector-container">
      <div className="result-page-selector-container1">
        <div onClick={()=>props.setPage(Math.max(props.currentPage - 1, 1))} className="result-page-selector-container2">
          <svg viewBox="0 0 1024 1024" className="result-page-selector-icon">
            <path d="M670.165 737.835l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-256 256c-16.683 16.683-16.683 43.691 0 60.331l256 256c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
          </svg>
        </div>
        {displayNums.map((n,i) => {
            if (n === -1){
                return (
                  <div key={i} className="result-page-selector-number">
                    <svg viewBox="0 0 1024 1024" className="result-page-selector-icon2">
                      <path d="M512 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM768 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM256 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26z"></path>
                    </svg>
                  </div>
                )
            }
            return(
                <div key={i} onClick={() => props.setPage(n)} className={n === props.currentPage ? "result-page-selector-current" :"result-page-selector-number"}>
                    <span className={n === props.currentPage ? "result-page-selector-text-current" :"result-page-selector-text"}>{n}</span>
                 </div>
        )})}
        <div onClick={() =>props.setPage(Math.min(props.currentPage + 1, props.numPages))} className="result-page-selector-container7">
          <svg
            viewBox="0 0 347.4285714285714 1024"
            className="result-page-selector-icon4"
          >
            <path d="M340 548.571c0 4.571-2.286 9.714-5.714 13.143l-266.286 266.286c-3.429 3.429-8.571 5.714-13.143 5.714s-9.714-2.286-13.143-5.714l-28.571-28.571c-3.429-3.429-5.714-8-5.714-13.143 0-4.571 2.286-9.714 5.714-13.143l224.571-224.571-224.571-224.571c-3.429-3.429-5.714-8.571-5.714-13.143s2.286-9.714 5.714-13.143l28.571-28.571c3.429-3.429 8.571-5.714 13.143-5.714s9.714 2.286 13.143 5.714l266.286 266.286c3.429 3.429 5.714 8.571 5.714 13.143z"></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default ExplorePageSelector
