import React from 'react'

import PropTypes from 'prop-types'

import './hero.css'

const Hero = (props) => {
  return (
    <div className="hero-hero">
      <div className="hero-container">
        <h1 className="hero-text">
          <span>
            Access to a
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="hero-text02">
            mountain
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>of knowledge</span>
        </h1>
        <span className="hero-text04">{props.text}</span>
        <div className="hero-container1">
          <img
            alt={props.image_alt}
            src={require('../../public/playground_assets/dashSS.png')}
            className="hero-image"
          />
        </div>
        <div className="hero-container2">
          <div className="hero-container3">
            <h1 className="hero-text05">{props.heading}</h1>
            <span className="hero-text06">{props.text1}</span>
          </div>
          <div className="hero-container4">
            <h1 className="hero-text07">{props.heading1}</h1>
            <span className="hero-text08">{props.text2}</span>
          </div>
          <div className="hero-container5">
            <h1 className="hero-text09">{props.heading2}</h1>
            <span className="hero-text10">{props.text3}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

Hero.defaultProps = {
  text2:
    'Alpinac uses your preferences and previous rating to give you  ski area recommendations tailored to you',
  heading: 'Real-Time Data',
  heading1: 'AI Powered ',
  heading2: 'Trip Planning',
  text: 'With ski area specific dashboards',
  image_alt: '',
  text1:
    'Real time data on mountain weather as well as lift and run status for over 25 resorts',
  text3:
    'Create and edit trips by adding resorts. Receive tailored advice and recommendations for additional resorts to try out.',
}

Hero.propTypes = {
  text2: PropTypes.string,
  heading: PropTypes.string,
  heading1: PropTypes.string,
  heading2: PropTypes.string,
  text: PropTypes.string,
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
  text1: PropTypes.string,
  text3: PropTypes.string,
}

export default Hero
