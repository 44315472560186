import React from 'react'

import PropTypes from 'prop-types'

import { formatDateToMMDDYY } from '../../../utils/dataProcessors'

import './review.css'

import SmallProfilePic from './small-profile-pic'

import StarRating from './StarRating'

const Review = (props) => {
    console.log(props)
  return (
    <div className="review-container">
      <div className="review-container1">
        <SmallProfilePic rideType={props.review.rideTypes} ability={props.review.abilityLevel} />
        <div>
            <span className="review-text">{props.review?.name == null ? "Anonymous" : props.review.name }</span>
            <div className="review-container4">
                <StarRating rating={props.review?.rating == null ? 0 : props.review.rating} size={25} />
            </div>
        </div>
      </div>
      <div className="review-container5">
        <span className="review-text2">{formatDateToMMDDYY(props.review.date)}</span>
      </div>
      <span>{props.review.review}</span>
    </div>
  )
}

Review.defaultProps = {
  text2: 'Favorite mountain in Utah ',
  image_alt: 'image',
  text1: 'Jake Rogers',
  text3: 'Skied 02/14/22',
  text: 'I first visited Alta a few years ago and after my first ski day there it became one of my favorite ski areas of all time. It had recently snowed a couple inches and the hiking terrain was amazing. Highly recommend this mountain to experienced skiers. One thing to keep in mind is there is a lot of traversing to do to get to some of the runs.',
}

Review.propTypes = {
  image_src: PropTypes.string,
  text2: PropTypes.string,
  image_alt: PropTypes.string,
  text1: PropTypes.string,
  text3: PropTypes.string,
  text: PropTypes.string,
}

export default Review
