export function getFeetFromVar(data){
    if (typeof(data) == "number"){
        return data
    } else if (data.includes("m")){
        return Number(data.replace(/[^0-9.]+/g,"")) / 3.28084
    } else {
        return Number(data.replace(/[^0-9.]+/g,""))
    }
}

export function getInfoDisplayReady(mountain){
    let retDict = {}
    retDict["Name"] = mountain.name
    retDict["Location"] = mountain.location ? mountain.location : mountain.state + ", " + mountain.country
    retDict["Rating"] = mountain.rating ? Math.round(mountain.rating * 100) / 100 + " (" +mountain.ratingCount + ")": "No ratings "
    retDict["TotalRuns"] = mountain.runs.total > 0 ? mountain.runs.total + " runs" : ""
    retDict["Vertical"] = mountain.vertical ? Math.round(getFeetFromVar(mountain.vertical)) + " ft. Vertical" : ""
    retDict["PassType"] = mountain.passType ? mountain.passType.replace("Mountain Collective", "MC") : "None"
    retDict["Acres"] = mountain.skiableArea ? Math.round(Number(String(mountain.skiableArea).replace(/[^0-9.]+/g,""))) + " Acres": ""
    retDict["Lifts"] = mountain.liftSystem ? mountain.liftSystem.slice(0, Math.min(12, mountain.liftSystem.length)) + "..." : mountain.numberOfLifts ?  mountain.numberOfLifts : mountain.liftCapacity ? "Cap. " + mountain.liftCapacity: ""
    retDict["BeginnerRuns"] = mountain.runs[0] > 0 ? Math.round(mountain.runs[0] * 100) + "%" : "0%"
    retDict["IntermediateRuns"] = mountain.runs[1] > 0 ? Math.round(mountain.runs[1] * 100) + "%" : "0%"
    retDict["ExpertRuns"] = (mountain.runs[2] || 0) > 0 ? Math.round((Math.max(mountain.runs[2],0) + Math.max(mountain.runs[3] || 0,0) + Math.max(mountain.runs[4] || 0,0)) * 100) + "%" : "0%";
    retDict["TriangleBeginnerRuns"] = mountain.runs[0] > 0 ? mountain.runs[0] : 0.01
    retDict["TriangleIntermediateRuns"] = mountain.runs[1] > 0 ? mountain.runs[1] : 0.01
    retDict["TriangleExpertRuns"] = (mountain.runs[2] || 0) > 0 ? (Math.max(mountain.runs[2],0) + Math.max(mountain.runs[3] || 0,0) + Math.max(mountain.runs[4] || 0,0)) : 0.01;
    retDict["RatingAI"] = "Login to view"
    retDict["BaseElevation"] = mountain.baseElevation ? mountain.baseElevation : "Unknown"
    retDict["Image"] = mountain.name ? mountain.name.replaceAll(" ","_") + ".jpg" : "Hyland_Ski_and_Snowboard_Area.jpg"
    retDict["imgFull"] = mountain.name ? mountain.name.replaceAll(" ","_") + ".jpg" : "Hyland_Ski_and_Snowboard_Area.jpg"
    retDict["percentMatch"] = Math.round(mountain.match)

    return retDict
}

export function calcMatches(data, userData) {
    const ability = userData["abilityLevel"]
    //const ability = 1
    let maxMatch = 0
    console.log(ability)

    data.map((m) => {
        const vertical  = getFeetFromVar(m.vertical)
        let area = Number(String(m.skiableArea).replace(/[^0-9.]+/g,""))
        if (area > 10000) { 
            area = 8000
        }
        const total = Number(String(m.runs["total"]).replace(/[^0-9.]+/g,""))

        const Beginner = m.runs[0] > 0 ? Number(m.runs[0]) : 0
        const Intermediate = m.runs[1] > 0 ? Number(m.runs[1]) : 0
        const AdvancedPlus =  (m.runs[2] || 0) > 0 ? Math.max(m.runs[2],0) + Math.max(m.runs[3] || 0,0) + Math.max(m.runs[4] || 0,0) : 0

        const mp = {"Beginner" : Beginner, "Intermediate": Intermediate, "Advanced": AdvancedPlus}


        if (Beginner === 0 || vertical === "" || area === "") {
            m["match"] = -1
        } else {
            if (ability === "New") {
                m["match"] = total * Beginner
                console.log(total * Beginner)
            } else if (ability === "Beginner" || ability === "Intermediate" || ability === "Advanced") {
                m["match"] = total * mp[ability] * 10 + vertical * area * mp[ability]
                console.log(m["match"])
            } else {
                let appRuns = AdvancedPlus

                m["match"] = total * appRuns + vertical * area * appRuns
            }
        }
        maxMatch = Math.max(maxMatch, m["match"])
        return m
    })

    data = data.map((m) => {
            //m["match"] = 15 * (m["match"] + 1) / (maxMatch + 1)
            m["match"] = 100 / (1 + Math.E ** (-3*2*m["match"] / maxMatch))
            return m
    })

    return data
}

export function formatDateToMMDDYY(isoString) {
    const date = new Date(isoString);

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);

    return `${month}/${day}/${year}`;
}