import React, { useState, useEffect } from 'react'

import './dash-full-size-map.css'

import Modal from 'react-modal';

const ModalFullMap = (props) => {

    if (!props.setIsModalOpen) {
        return null;
      }

  return (
    <Modal
    isOpen={props.isModalOpen}
    onRequestClose={() => props.setIsModalOpen(false)}
    shouldCloseOnOverlayClick={true}
    ariaHideApp={false}
    className="dash-full-size-map-modal"
    >
        <div className="dash-full-size-map-modal-div" >
            <div className='dash-full-size-map-modal-x' onClick={() => props.setIsModalOpen(false)}><span>Close</span></div>
            <img className="dash-full-size-map-modal-img" alt="full trail map" src={props.img}/>
        </div>
    </Modal>
  )
}

export default ModalFullMap
