import React from 'react'

const StarRating = ({ rating, size }) => {
    const starCount = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
  
    const fullStars = [...Array(starCount)].map((_, index) => (
        <svg key={index} style={{height: size, width: size}} viewBox="0 0 1024 1024" className="reviews-icon">
            <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
        </svg>
    ));
    const halfStarElement = halfStar ? (
        <svg style={{height: size, width: size}}  viewBox="0 0 1024 1024" className="reviews-icon">
        <path d="M512 658l160 96-42-182 142-124-188-16-72-172v398zM938 394l-232 202 70 300-264-160-264 160 70-300-232-202 306-26 120-282 120 282z"></path>
      </svg>
              ) : null;
    const emptyStars = [...Array(5 - starCount - (halfStar ? 1 : 0))].map(
      (_, index) => (
        <svg style={{height: size, width: size}}  key={index} viewBox="0 0 1024 1024" className="reviews-icon">
          <path d="M512 658l160 96-42-182 142-124-188-16-72-172-72 172-188 16 142 124-42 182zM938 394l-232 202 70 300-264-160-264 160 70-300-232-202 306-26 120-282 120 282z"></path>
        </svg>
      )
    );
  
    return (
      <div className="star-rating">
        {fullStars}
        {halfStarElement}
        {emptyStars}
      </div>
    );
  };

export default StarRating