import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import { Helmet } from 'react-helmet'

import NavBarPublic from '../components/nav-bar-public'
import NavBarPrivate from '../components/nav-bar-private'
import MountainCard from '../components/mountain-card'
import Footer from '../components/footer'
import './explore-page.css'
import LoadingIcon from "../components/loading"

import {getAllMountains} from "../../../utils/apiHelper.js"

import {
    useParams
  } from "react-router-dom";
  
import { authContext } from "../../../utils/useAuth";
import ExplorePageSelector from '../components/explore-page-selector';

import { Rating } from 'react-simple-star-rating'

import {calcMatches} from '../../../utils/dataProcessors';

const ExplorePage = () => {

  const DISPLAY_COUNT = 60

  // Get user and auth info
  const authCont = React.useContext(authContext)
  const authed = authCont.authed;
  const user = authCont.user

  let { query } = useParams();

  const [allMountains, setMountains] = useState([]);
  const [toDisplayMountains, setToDisplayMountains] = useState([]);
  const [displayMountains, setDisplayMountains] = useState([])
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  const [inputValue, setInputValue] = useState(query ? query : "");


  const [currentFilters, setCurrentFilters] = useState(["Country"])
  const [rating, setRating] = useState(0)
  const [isCheckedRating, setIsCheckedRating] = useState(false);
  const [isCheckedRuns, setIsCheckedRuns] = useState(false);
  const [isCheckedAcres, setIsCheckedAcres] = useState(false);
  const [isCheckedPass, setIsCheckedPass] = useState(false);
  const [isCheckedCountry, setIsCheckedCountry] = useState(true);
  const [runs, setRuns] = useState(50)
  const [acres, setAcres] = useState(1000)


  function handleCheckboxChange(event, filterType) {
    let checked = event
    if (typeof(event) != "boolean"){
        checked = event.target.checked
    }

    switch(filterType) {
      case "Rating":
        setIsCheckedRating(checked);
        break;
      case "Runs":
        setIsCheckedRuns(checked);
        break;
      case "Acres":
        setIsCheckedAcres(checked);
        break;
      case "Pass":
        setIsCheckedPass(checked);
        break;
      case "Country":
        setIsCheckedCountry(checked);
        break;
      default:
        break;
    }
    handleFilterChange(filterType);
  }

  const options = [
    { value: 'Epic', label: 'EPIC' },
    { value: 'Ikon', label: 'IKON' },
    { value: 'Mountain Collective', label: 'Mountain Collective' },
    { value: 'Indy', label: 'Indy' },
  ];

  const runOptions = [
    { value: '0', label: 'Total' },
    { value: '1', label: 'Beginner' },
    { value: '2', label: 'Intermediate' },
    { value: '3', label: 'Advanced' },
  ];

  const genOptions = [
    { value: 'availableInfo', label: 'Available Info'},
    { value: 'rating', label: 'Rating' },
    { value: 'skiableArea', label: 'Skiable Acres' },
    { value: 'vertical', label: 'Vertical' },
    { value: 'match', label: '% Match', isDisabled: !authed}
  ];

  const countryOptions = [
    { value: 'Chile', label: 'Chile'}, 
    { value: 'Brazil', label: 'Brazil'}, 
    { value: 'Japan (日本)', label: 'Japan (日本)'}, 
    { value: 'Spain', label: 'Spain'}, 
    { value: 'New Zealand', label: 'New Zealand'}, 
    { value: 'Australia', label: 'Australia'}, 
    { value: 'Iran', label: 'Iran'}, 
    { value: 'United States', label: 'United States'},
    { value: 'Switzerland', label: 'Switzerland'},
    { value: 'Argentina', label: 'Argentina'},
    { value: 'China', label: 'China'},
    { value: 'France', label: 'France'},
    { value: 'Canada', label: 'Canada'},
    { value: 'Austria', label: 'Austria'},
    { value: 'Germany', label: 'Germany'},
    { value: 'Russia', label: 'Russia'},
    { value: 'Italy', label: 'Italy'},
    { value: 'South Korea', label: 'South Korea'}
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedRunOptions, setSelectedRunOptions] = useState({ value: '0', label: 'Total' });
  const [selectedGenOptions, setSelectedGenOptions] = useState({ value: 'availableInfo', label: 'Available Info'});
  const [selectedCountryOptions, setSelectedCountryOptions] = useState([{ value: 'United States', label: 'United States'}, { value: 'Canada', label: 'Canada'},])

  const [radio, setRadio] = useState('gen');

  function handleSelectChange(selectedOptions) {
    setSelectedOptions(selectedOptions);
  }

  function handleRunSelectChange(selectedOptions) {
    setSelectedRunOptions(selectedOptions);
  }

  function handleGenSelectChange(selectedOptions) {
    setSelectedGenOptions(selectedOptions);
  }
  function handleCountrySelectChange(selectedOptions) {
    setSelectedCountryOptions(selectedOptions);
  }


  const handleRating = (rate) => {
    setRating(rate)
  }

  const handleFilterChange = (filterName) => {
    if (currentFilters.includes(filterName)){
        let currentFiltersNew = currentFilters.filter(x => x !== filterName)
        setCurrentFilters(currentFiltersNew)
    } else {
        let currentFiltersNew = currentFilters
        currentFiltersNew.push(filterName)
        setCurrentFilters(currentFiltersNew)
    }
  }

  const updateAllMtnRating = (name, rating) => {
    let updatedMtns = allMountains
    const index = updatedMtns.findIndex(item => item.name === name);
    let count = updatedMtns[index]["ratingCount"] ? updatedMtns[index]["ratingCount"] : 0
    updatedMtns[index]["rating"] = updatedMtns[index]["rating"] ?  (updatedMtns[index]["rating"] * count + rating) / (count+1): rating
    updatedMtns[index]["ratingCount"] = count+1
    setMountains(updatedMtns)
  }


  const fetchMountains = async () => {
    try {
      let json = await getAllMountains();
      return { success: true, data: json };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }

const handleFilter = (data, queryBy) => {
    let q = queryBy.toLowerCase();
    setToDisplayMountains(data.filter(m =>
        (m.name.toLowerCase().includes(q) ||
        m.state.toLowerCase().includes(q) ||
        m.country.toLowerCase().includes(q) ||
        m.location.toLowerCase().includes(q) || 
        m.nearestMajorCity.toLowerCase().includes(q)) && 
        checkAgainstFilters(m))
        .sort((a, b) => Object.values(b).filter(val => val !== "").length - Object.values(a).filter(val => val !== "").length)
        .sort((a, b) => getSorted(a,b)));
}

const checkAgainstFilters = (mtn) => {
    let res = true
    for(var f in currentFilters){
        switch(currentFilters[f]) {
            case "Rating":
            res &= mtn["rating"] && mtn["rating"] >= rating
            break;
            case "Runs":
            res &= Math.round(Number(String(mtn.runs.total).replace(/[^0-9\.]+/g,""))) > runs
            break;
            case "Acres":
            res &= Math.round(Number(String(mtn.skiableArea).replace(/[^0-9\.]+/g,""))) > acres
            break;
            case "Pass":
            res &= selectedOptions.some(option => mtn["passType"].includes(option.value))
            break;
            case "Country":
            res &= selectedCountryOptions.some(option => mtn["country"].includes(option.value))
            break;
            default:
            break;
        }
    }
    return res
}

const getSorted = (a, b) => {
    let sorty = selectedRunOptions.label
    if (radio === "gen") {
        sorty = selectedGenOptions.label
    }

    switch(sorty) {
        case "Rating":
            let bv = b["rating"] ? b["rating"] : 0
            let av = a["rating"] ? a["rating"] : 0
            return bv-av
        case "% Match":
            return b["match"] - a["match"]
        case "Skiable Acres":
            return Math.round(Number(String(b.skiableArea).replace(/[^0-9\.]+/g,""))) - Math.round(Number(String(a.skiableArea).replace(/[^0-9\.]+/g,"")))
        case "Vertical":
            return Math.round(Number(String(b.vertical).replace(/[^0-9\.]+/g,""))) - Math.round(Number(String(a.vertical).replace(/[^0-9\.]+/g,"")))
        case "Total":
            return Math.round(Number(String(b.runs.total).replace(/[^0-9\.]+/g,""))) - Math.round(Number(String(a.runs.total).replace(/[^0-9\.]+/g,"")))
        case "Beginner":
            return Math.round(Number(String(b.runs.total).replace(/[^0-9\.]+/g,""))) * Math.round(Number(String(b.runs["0"]).replace(/[^0-9\.]+/g,""))) - Math.round(Number(String(a.runs.total).replace(/[^0-9\.]+/g,""))) * Math.round(Number(String(a.runs["0"]).replace(/[^0-9\.]+/g,"")))
        case "Intermediate":
            return Math.round(Number(String(b.runs.total).replace(/[^0-9\.]+/g,""))) * Math.round(Number(String(b.runs["1"]).replace(/[^0-9\.]+/g,""))) - Math.round(Number(String(a.runs.total).replace(/[^0-9\.]+/g,""))) * Math.round(Number(String(a.runs["1"]).replace(/[^0-9\.]+/g,"")))
        case "Advanced":
            return Math.round(Number(String(b.runs.total).replace(/[^0-9\.]+/g,""))) * Math.round(Number(String(b.runs["2"]).replace(/[^0-9\.]+/g,""))) - Math.round(Number(String(a.runs.total).replace(/[^0-9\.]+/g,""))) * Math.round(Number(String(a.runs["2"]).replace(/[^0-9\.]+/g,"")))
        default:
        return 0;
    }
}

  useEffect(() => {
    (async () => {
      setLoading(true);
      let res = await fetchMountains();
      if (res.success) {
        let d = res.data
        if (authed && user) {
            d = calcMatches(d, user)
        }
        setMountains(d);
        if(query){
            handleFilter(d, query)
        } else {
            handleFilter(d, "")
        }
        setLoading(false);
      }
    })();
  }, [query]);

  useEffect(() => {
    handleFilter(allMountains, inputValue)
  }, [allMountains, inputValue, selectedCountryOptions, selectedGenOptions, radio, selectedOptions, selectedRunOptions, acres, runs, rating, currentFilters, isCheckedAcres, isCheckedCountry, isCheckedPass, isCheckedRating, isCheckedRuns])

  useEffect(() => {
    if (toDisplayMountains.length <= DISPLAY_COUNT){
        setNumPages(1)
        setDisplayMountains(toDisplayMountains)
    } else {
        setNumPages(Math.ceil(toDisplayMountains.length/DISPLAY_COUNT))
        setDisplayMountains(toDisplayMountains.slice((currentPage-1) * DISPLAY_COUNT,(currentPage*DISPLAY_COUNT)))
    }
  }, [toDisplayMountains, currentPage])

  const onChangeHandler = event => {
    setInputValue(event.target.value);
  };

  const onSubmitHandler = event => {
      event.preventDefault();
      handleFilter(allMountains, inputValue)
  };

  const viewportHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );

  useEffect(() => {
    function handleScroll() {
        if (filterRef.current && footerRef.current) {
          const filterBox = filterRef.current.getBoundingClientRect();
          const footerBox = footerRef.current.getBoundingClientRect();
          setFreezeFilter(filterBox.bottom >= footerBox.bottom-0.5 && filterBox.bottom < viewportHeight)
        }
      }
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
  }, [viewportHeight])

  const [freeze, setFreezeFilter] = useState(false)
  const filterRef = useRef(null)
  const footerRef = useRef(null)

  const [hideMobileFilter, setHideMobileFilter] = useState(true)

  return (
    <div className="explore-page-container">
      <Helmet>
        <title>Explore Page - Alpinac</title>
        <meta property="og:title" content="Explore Page - Alpinac" />
        <meta name="description" content="Browse thousands of ski resorts and get all the information you need to plan your next ski trip. Sort and filter mountains by rating, vertical, skiable acreage, number of runs, and more. Keep track of resorts you've been to and resorts you wish to visit." />
      </Helmet>
      <div className="explore-page-top-container">
        <div className="explore-page-container01" style={{zIndex: 999}}>
            {!authed ?
          <NavBarPublic rootClassName="nav-bar-public-root-class-name"></NavBarPublic>
          :
          <NavBarPrivate rootClassName="nav-bar-private-root-class-name"></NavBarPrivate>
        }
        </div>
        <div className="explore-page-container02"></div>
        <div className="explore-page-main">
          <div className="explore-page-container03" style={freeze ? {alignItems: 'flex-end'} : {}}>
            <div className={hideMobileFilter  ? "explore-page-container04".concat(" hide"): "explore-page-container04 test"}>
            <div className='explore-page-container-x' onClick={() => setHideMobileFilter(true)}><span>Close</span></div>
            <div ref={filterRef} className={freeze ? "explore-page-container-freeze" : "explore-page-container10"}>
              <div className="explore-page-container11">
                <h1 className="explore-page-text05">Sort</h1>
                <div className="explore-page-container12">
                  <div className="explore-page-container13">
                    <span className="explore-page-general-attributes">
                      General Attributes
                    </span>
                  </div>
                  <input
                    type="radio"
                    name="radio"
                    className="explore-page-radiobutton"
                    value="gen"
                    checked={radio === "gen"}
                    onChange={event => setRadio(event.target.value)}
                  />
                </div>
                <Select
                    className="explore-page-select"
                    options={genOptions}
                    value={selectedGenOptions}
                    onChange={handleGenSelectChange}
                />
                <div className="explore-page-container14">
                  <div className="explore-page-container15">
                    <span className="explore-page-general-attributes1">
                      Number of Runs
                    </span>
                  </div>
                  <input
                    type="radio"
                    name="radio"
                    className="explore-page-radiobutton1"
                    value="runs"
                    checked={radio === "runs"}
                    onChange={event => setRadio(event.target.value)}
                  />
                </div>
                <Select
                    className="explore-page-select"
                    options={runOptions}
                    value={selectedRunOptions}
                    onChange={handleRunSelectChange}
                />
                <h1 className="explore-page-text06">Filter</h1>
                <div className="explore-page-container22">
                  <div className="explore-page-container23">
                    <span className="explore-page-general-attributes5">
                      <span>Country</span>
                    </span>
                  </div>
                  <input
                    type="checkbox"
                    checked={isCheckedCountry}
                    onChange={(event) => handleCheckboxChange(event, "Country")}
                    className="explore-page-checkbox1"
                  />
                </div>
                <Select
                    className="explore-page-select"
                    options={countryOptions}
                    value={selectedCountryOptions}
                    onChange={handleCountrySelectChange}
                    classNamePrefix="react-select"
                    isMulti
                />
                <div className="explore-page-container18">
                  <div className="explore-page-container19">
                    <span className="explore-page-general-attributes3">
                      <span>Rating &gt; </span>
                      <span className="explore-page-text08">{rating}</span>
                    </span>
                  </div>
                  <input
                    type="checkbox"
                    checked={isCheckedRating}
                    onChange={(event) => handleCheckboxChange(event, "Rating")}
                    className="explore-page-checkbox1"
                  />
                </div>
                <div className='explore-page-rating'>
                    <Rating
                        onClick={handleRating}
                        allowFraction={true}
                        transition={true}
                    />
                </div>
                <div className="explore-page-container20">
                  <div className="explore-page-container21">
                    <span className="explore-page-general-attributes4">
                      <span>Total Runs &gt; </span>
                      <span className="explore-page-text10">{runs}</span>
                    </span>
                  </div>
                  <input
                    type="checkbox"
                    checked={isCheckedRuns}
                    onChange={(event) => handleCheckboxChange(event, "Runs")}
                    className="explore-page-checkbox1"
                  />
                </div>
                <input className="explore-page-slider" type="range" max={100} min={0} step={5} value={runs} onChange={(event) => setRuns(event.target.value)}/>
                <div className="explore-page-container22">
                  <div className="explore-page-container23">
                    <span className="explore-page-general-attributes5">
                      <span>Skiable Acres &gt; </span>
                      <span className="explore-page-text12">{acres}</span>
                    </span>
                  </div>
                  <input
                    type="checkbox"
                    checked={isCheckedAcres}
                    onChange={(event) => handleCheckboxChange(event, "Acres")}
                    className="explore-page-checkbox1"
                  />
                </div>
                <input className="explore-page-slider" type="range" max={2000} min={0} step={10} value={acres} onChange={(event) => setAcres(event.target.value)}/>
                <div className="explore-page-container22">
                  <div className="explore-page-container23">
                    <span className="explore-page-general-attributes5">
                      <span>Pass Type</span>
                    </span>
                  </div>
                  <input
                    type="checkbox"
                    checked={isCheckedPass}
                    onChange={(event) => handleCheckboxChange(event, "Pass")}
                    className="explore-page-checkbox1"
                  />
                </div>
                <Select
                    className="explore-page-select"
                    options={options}
                    value={selectedOptions}
                    onChange={handleSelectChange}
                    isMulti
                />
                <div style={{height: "200px"}}></div>
              </div>
            </div>
          
            </div>
            <div className="explore-page-container05">
              <div className="explore-page-container06">
                <div className= "explore-page-search-and-filter">
                <div className="explore-page-container07">
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="explore-page-icon"
                  >
                    <path d="M658.286 475.429c0-141.143-114.857-256-256-256s-256 114.857-256 256 114.857 256 256 256 256-114.857 256-256zM950.857 950.857c0 40-33.143 73.143-73.143 73.143-19.429 0-38.286-8-51.429-21.714l-196-195.429c-66.857 46.286-146.857 70.857-228 70.857-222.286 0-402.286-180-402.286-402.286s180-402.286 402.286-402.286 402.286 180 402.286 402.286c0 81.143-24.571 161.143-70.857 228l196 196c13.143 13.143 21.143 32 21.143 51.429z"></path>
                  </svg>
                  <form onSubmit={onSubmitHandler}>
                  <input
                    type="text"
                    placeholder="Search by name or location"
                    className="explore-page-textinput input"
                    onChange={onChangeHandler}
                    value={inputValue}
                  />
                  </form>
                  <div
                    onClick={() => handleFilter(allMountains, inputValue)}
                    className="explore-page-link"
                  >
                    <svg viewBox="0 0 1024 1024" className="explore-page-icon2">
                      <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                  </div>
                </div>
                <div onClick={() => setHideMobileFilter(false)} className="filter-button">
                    <svg fill="#000000" viewBox="0 0 24 24" className='explore-page-icon2' xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" ></g><g id="SVGRepo_iconCarrier"><path d="M23,4a1,1,0,0,1-1,1H19a1,1,0,0,1,0-2h3A1,1,0,0,1,23,4ZM2,3h8.184a3,3,0,1,1,0,2H2A1,1,0,0,1,2,3ZM12,4a1,1,0,1,0,1-1A1,1,0,0,0,12,4ZM1,12a1,1,0,0,1,1-1H6.184a3,3,0,1,1,0,2H2A1,1,0,0,1,1,12Zm7,0a1,1,0,1,0,1-1A1,1,0,0,0,8,12Zm14-1H15a1,1,0,0,0,0,2h7a1,1,0,0,0,0-2Zm0,8H17a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2ZM1,20a1,1,0,0,1,1-1H8.184a3,3,0,1,1,0,2H2A1,1,0,0,1,1,20Zm9,0a1,1,0,1,0,1-1A1,1,0,0,0,10,20Z"></path></g></svg>
                </div>
                </div>
                <div className="explore-page-container08">
                    {currentFilters.map(f => (
                        <div onClick={() =>handleCheckboxChange(false, f)} className="explore-page-sort-or-filter-indicator">
                            <span className="explore-page-text01">{f}</span>
                        </div>
                    ))}
                </div>
              </div>
              <div ref={footerRef} className="explore-page-container09">
                <div className="explore-page-cards-container">
                    {loading ? <LoadingIcon stye={{zIndex: 1}}/> : 
                  <h1 className="explore-page-text02">
                    {toDisplayMountains.length > DISPLAY_COUNT ? (currentPage-1) * DISPLAY_COUNT + " - " + currentPage * DISPLAY_COUNT + " out of " + toDisplayMountains.length + " Results" : "All "+ toDisplayMountains.length+" Results"}
                  </h1>
                  }{displayMountains.map((mountain, i) => (
                                          <MountainCard
                                          viewportHeight={viewportHeight}
                                          key={i}
                                          mountain={mountain}
                                          updateAllMtnRating={updateAllMtnRating}
                                        ></MountainCard>
                    ))}
                </div>
                {numPages > 1 && <ExplorePageSelector numPages={numPages} currentPage={currentPage} setPage={setCurrentPage}/>}
              </div>
            </div>
            </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default ExplorePage
