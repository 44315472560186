import React, { useState, useEffect } from 'react'

import RatingDistribution from './rating-distribution'
import Review from './review'
import './reviews.css'
import Select from 'react-select';
import StarRating from './StarRating';

const Reviews = (props) => {

    const genOptions = [
        { value: 'Recent', label: 'Recent'},
        { value: 'Top', label: 'Top' },
        { value: 'Critical', label: 'Critical' },
        ];

    const [selectedReviewOrder, seSelectedReviewOrder] = useState(genOptions[0])

    function handleSelectedReviewOrderChange(selectedOption){
        seSelectedReviewOrder(selectedOption)
    }

    const sortReviews = (reviews) => {
        if (selectedReviewOrder.value === 'Recent') {
          return reviews.sort((a, b) => new Date(b.date) - new Date(a.date));
        } else if (selectedReviewOrder.value === 'Top') {
          return reviews.sort((a, b) => b.rating - a.rating);
        } else if (selectedReviewOrder.value === 'Critical') {
          return reviews.sort((a, b) => a.rating - b.rating);
        }
        return reviews;
      };

      const [abilityRating, setAbilityRatings] = useState({"New": {"rating": 0, "count": 0}, "Beginner": {"rating": 0, "count": 0}, "Intermediate": {"rating": 0, "count": 0}, "Advanced": {"rating": 0, "count": 0}, "Expert": {"rating": 0, "count": 0}, "Extreme": {"rating": 0, "count": 0}})

      useEffect(() => {
        if (props.ratings == null){
            return
        }

        const newAbilityRatings = {"New": {"rating": 0, "count": 0}, "Beginner": {"rating": 0, "count": 0}, "Intermediate": {"rating": 0, "count": 0}, "Advanced": {"rating": 0, "count": 0}, "Expert": {"rating": 0, "count": 0}, "Extreme": {"rating": 0, "count": 0}};
    
        props.ratings.forEach((entry) => {
          const { abilityLevel, rating } = entry;
    
          if (newAbilityRatings[abilityLevel]) {
            newAbilityRatings[abilityLevel].rating += rating;
            newAbilityRatings[abilityLevel].count += 1;
          }
        });
    
        for (const level in newAbilityRatings) {
          if (newAbilityRatings[level].count > 0) {
            newAbilityRatings[level].rating = Math.round(newAbilityRatings[level].rating * 10 / newAbilityRatings[level].count) / 10;
          }
        }
    
        setAbilityRatings(newAbilityRatings);
      }, [props.ratings]);

  return (
    <div className="reviews-reviews">
      <div className="reviews-container">
        <h1 className="reviews-text">Ratings</h1>
        <div className="reviews-container01">
            <StarRating size={40} rating={props.rating == null ? 0 : Math.round(props.rating*100) / 100 }/>
          <span className="reviews-ovarall-rating">{props.rating == null ? "0 (0)" : Math.round(props.rating*100)/100 + " (" + props.numberOfRatings + ")"}</span>
        </div>
        <RatingDistribution ratings={props?.ratings == null ? [] : props.ratings } />
        <div className="reviews-container02">
          <div className="reviews-container03">
            <div className="reviews-container04">
              <svg viewBox="0 0 650 1024" className="reviews-icon10">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="reviews-icon12">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
              <svg viewBox="180 0 1024 1024" className="reviews-icon14">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
            </div>
            {/* {props.ratings == null ? <div/> : props.ratings.map((rating) => {
                return (
                    <div>TETSTS</div>
                );
            })} */}
            <div className="reviews-container05">
              <StarRating rating={abilityRating.Extreme.rating} size={30}/>
              <span className="reviews-ovarall-rating1">
              {abilityRating.Extreme.count > 0 ? abilityRating.Extreme.rating + " (" + abilityRating.Extreme.count +")" : "0 (0)"}
              </span>
            </div>
          </div>
          <div className="reviews-container06">
            <div className="reviews-container07">
              <svg viewBox="0 0 650 1024" className="reviews-icon26">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
              <svg viewBox="180 0 1024 1024" className="reviews-icon28">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
            </div>
            <div className="reviews-container08">
                <StarRating rating={abilityRating.Expert.rating} size={30}/>
              <span className="reviews-ovarall-rating2">
              {abilityRating.Expert.count > 0 ? abilityRating.Expert.rating + " (" + abilityRating.Expert.count +")" : "0 (0)"}
              </span>
            </div>
          </div>
          <div className="reviews-container09">
            <div className="reviews-container10">
              <svg viewBox="0 0 1024 1024" className="reviews-icon40">
                <path d="M512 0l-320 512 320 512 320-512z"></path>
              </svg>
            </div>
            <div className="reviews-container11">
            <StarRating rating={abilityRating.Advanced.rating} size={30}/>
              <span className="reviews-ovarall-rating3">
              {abilityRating.Advanced.count > 0 ? abilityRating.Advanced.rating + " (" + abilityRating.Advanced.count +")" : "0 (0)"}
              </span>
            </div>
          </div>
          <div className="reviews-container12">
            <div className="reviews-container13">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="reviews-icon52"
              >
                <path d="M877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
            </div>
            <div className="reviews-container14">
            <StarRating rating={abilityRating.Intermediate.rating} size={30}/>
              <span className="reviews-ovarall-rating4">
              {abilityRating.Intermediate.count > 0 ? abilityRating.Intermediate.rating + " (" + abilityRating.Intermediate.count +")" : "0 (0)"}
              </span>
            </div>
          </div>
          <div className="reviews-container15">
            <div className="reviews-container16">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="reviews-icon64"
              >
                <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
              </svg>
            </div>
            <div className="reviews-container17">
            <StarRating rating={abilityRating.Beginner.rating} size={30}/>
              <span className="reviews-ovarall-rating5">
              {abilityRating.Beginner.count > 0 ? abilityRating.Beginner.rating + " (" + abilityRating.Beginner.count +")" : "0 (0)"}
              </span>
            </div>
          </div>
          <div className="reviews-container18">
            <div className="reviews-container19">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="reviews-icon76"
              >
                <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
              </svg>
            </div>
            <StarRating rating={abilityRating.New.rating} size={30}/>
            <div className="reviews-container20">
              <span className="reviews-ovarall-rating6">
                {abilityRating.New.count > 0 ? abilityRating.New.rating + " (" + abilityRating.New.count +")" : "0 (0)"}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="reviews-container21">
          <h3 className="reviews-text01">{props.heading2}</h3>
          <div className="reviews-container22">
            <span>{props.text1}</span>
            <div className="reviews-container23">
              <svg viewBox="0 0 1024 1024" className="reviews-icon88">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
              <span className="reviews-text03">{props.text4}</span>
            </div>
          </div>
          <div className="reviews-container24">
            <span>{props.text2}</span>
            <div className="reviews-container25">
              <svg viewBox="0 0 1024 1024" className="reviews-icon90">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
              <span className="reviews-text05">{props.text5}</span>
            </div>
          </div>
          <div className="reviews-container26">
            <span>{props.text3}</span>
            <div className="reviews-container27">
              <svg viewBox="0 0 1024 1024" className="reviews-icon92">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
              <span className="reviews-text07">{props.text6}</span>
            </div>
          </div>
          <svg viewBox="0 0 1024 1024" className="reviews-icon94">
            <path d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM554 298v172h172v84h-172v172h-84v-172h-172v-84h172v-172h84z"></path>
          </svg>
          <span>{props.text}</span>
        </div> */}
      </div>
      <div className="reviews-container28">
        <h1 className="reviews-text09">{props.heading1}</h1>
        {props.reviews ? <Select
                    className="select-dropdown"
                    options={genOptions}
                    value={selectedReviewOrder}
                    onChange={handleSelectedReviewOrderChange}
                /> : 
                <div>
                    <h2 className='empty-reviews-text'>No reviews yet, be the first to add a review!</h2>
                </div>}
        {props.reviews ? sortReviews(props.reviews).map((review, i) => (
            <Review review={review} />
        )) : <div/>}
      </div>
    </div>
  )
}

Reviews.defaultProps = {
  heading: 'Ratings',
  heading1: 'Reviews',
  OvarallRating: '4.96 (33)',
  heading2: 'Your visits',
  text: 'Add More',
  text1: '02/12/22',
  text2: '01/02/22',
  text3: '12/22/21',
  OvarallRating1: '5.00 (6)',
  OvarallRating2: '5.00 (16)',
  OvarallRating3: '5.00 (5)',
  OvarallRating4: '5.00 (3)',
  OvarallRating5: '4.50 (2)',
  OvarallRating6: '0 reviews',
  text4: '4.60',
  text5: '4.60',
  text6: '4.60',
}

export default Reviews
