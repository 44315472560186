import React from 'react'

import PropTypes from 'prop-types'

import './phone-poster.css'

const PhonePoster = (props) => {
  return (
    <div className="phone-poster-container">
      <img
        alt="iphone app"
        src={require('../../public/playground_assets/pastedimage-0p2w-1500w.png')}
        className="phone-poster-pasted-image"
      />
      <span className="phone-poster-text">
        <span>
          Coming soon to the
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <span className="phone-poster-text2">App Store</span>
        <span>
          {' '}
          and
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <span className="phone-poster-text4">Google Play</span>
      </span>
    </div>
  )
}

export default PhonePoster
